import React, { Component } from "react";

class PrivacyNotice extends Component {
  render() {
    return (
      <div className="imprint" style={{ textAlign: "left", padding: "30px" }}>
        <h1>Impressum</h1>
        <h2 id="m46">Diensteanbieter</h2>
        <p>Sebastian Schemmel-Schmähling</p>
        <p>Schweppermannstraße 23</p>
        <p>90408 Nürnberg</p>
        <h2 id="m56">Kontaktmöglichkeiten</h2>
        <p>
          E-Mail-Adresse:{" "}
          <a href="mailto:sebastian@mutcho.app">sebastian@mutcho.app</a>
        </p>
        <p>
          Kontaktformular:{" "}
          <a href="https://mutcho.app" target="_blank">
            https://mutcho.app
          </a>
        </p>
        <h2 id="m65">Haftungs- und Schutzrechtshinweise</h2>
        <p>
          <strong>Haftungsausschluss</strong>: Die Inhalte dieses
          Onlineangebotes wurden sorgfältig und nach unserem aktuellen
          Kenntnisstand erstellt, dienen jedoch nur der Information und
          entfalten keine rechtlich bindende Wirkung, sofern es sich nicht um
          gesetzlich verpflichtende Informationen (z.B. das Impressum, die
          Datenschutzerklärung, AGB oder verpflichtende Belehrungen von
          Verbrauchern) handelt. Wir behalten uns vor, die Inhalte vollständig
          oder teilweise zu ändern oder zu löschen, soweit vertragliche
          Verpflichtungen unberührt bleiben. Alle Angebote sind freibleibend und
          unverbindlich.{" "}
        </p>
        <p>
          <strong>Links auf fremde Webseiten</strong>: Inhalte fremder
          Webseiten, auf die wir direkt oder indirekt verweisen, liegen
          außerhalb unseres Verantwortungsbereiches und machen wir uns nicht zu
          Eigen. Für alle Inhalte und insbesondere für Schäden, die aus der
          Nutzung der in den verlinkten Webseiten aufrufbaren Informationen
          entstehen, haftet allein der Anbieter der verlinkten Webseiten.
        </p>
        <p>
          <strong>Urheberrechte und Markenrechte</strong>: Alle auf dieser
          Website dargestellten Inhalte, wie Texte, Fotografien, Grafiken,
          Marken und Warenzeichen sind durch die jeweiligen Schutzrechte
          (Urheberrechte, Markenrechte) geschützt. Die Verwendung,
          Vervielfältigung usw. unterliegen unseren Rechten oder den Rechten der
          jeweiligen Urheber bzw. Rechteverwalter.
        </p>
        <p>
          <strong>Hinweise auf Rechtsverstöße</strong>: Sollten Sie innerhalb
          unseres Internetauftritts Rechtsverstöße bemerken, bitten wir Sie uns
          auf diese hinzuweisen. Wir werden rechtswidrige Inhalte und Links nach
          Kenntnisnahme unverzüglich entfernen.
        </p>
        <p class="seal">
          <a
            href="https://datenschutz-generator.de/?l=de"
            title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            Erstellt mit kostenlosem Datenschutz-Generator.de von Dr. Thomas
            Schwenke
          </a>
        </p>
        <h1>Datenschutzerklärung</h1>
        <h2>Einleitung</h2>
        <p>
          Mit der folgenden Datenschutzerklärung möchten wir Sie darüber
          aufklären, welche Arten Ihrer personenbezogenen Daten (nachfolgend
          auch kurz als "Daten“ bezeichnet) wir zu welchen Zwecken und in
          welchem Umfang verarbeiten. Die Datenschutzerklärung gilt für alle von
          uns durchgeführten Verarbeitungen personenbezogener Daten, sowohl im
          Rahmen der Erbringung unserer Leistungen als auch insbesondere auf
          unseren Webseiten, in mobilen Applikationen sowie innerhalb externer
          Onlinepräsenzen, wie z.B. unserer Social-Media-Profile (nachfolgend
          zusammenfassend bezeichnet als "Onlineangebot“).
        </p>
        <p>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p>
        <p>Stand: 8. April 2021</p>
        <h2>Inhaltsübersicht</h2>{" "}
        <ul class="index">
          <li>
            <a class="index-link" href="#m14">
              Einleitung
            </a>
          </li>
          <li>
            <a class="index-link" href="#m3">
              Verantwortlicher
            </a>
          </li>
          <li>
            <a class="index-link" href="#mOverview">
              Übersicht der Verarbeitungen
            </a>
          </li>
          <li>
            <a class="index-link" href="#m13">
              Maßgebliche Rechtsgrundlagen
            </a>
          </li>
          <li>
            <a class="index-link" href="#m27">
              Sicherheitsmaßnahmen
            </a>
          </li>
          <li>
            <a class="index-link" href="#m25">
              Übermittlung von personenbezogenen Daten
            </a>
          </li>
          <li>
            <a class="index-link" href="#m24">
              Datenverarbeitung in Drittländern
            </a>
          </li>
          <li>
            <a class="index-link" href="#m134">
              Einsatz von Cookies
            </a>
          </li>
          <li>
            <a class="index-link" href="#m225">
              Bereitstellung des Onlineangebotes und Webhosting
            </a>
          </li>
          <li>
            <a class="index-link" href="#m182">
              Kontaktaufnahme
            </a>
          </li>
          <li>
            <a class="index-link" href="#m17">
              Newsletter und elektronische Benachrichtigungen
            </a>
          </li>
          <li>
            <a class="index-link" href="#m638">
              Werbliche Kommunikation via E-Mail, Post, Fax oder Telefon
            </a>
          </li>
          <li>
            <a class="index-link" href="#m264">
              Onlinemarketing
            </a>
          </li>
          <li>
            <a class="index-link" href="#m136">
              Präsenzen in sozialen Netzwerken (Social Media)
            </a>
          </li>
          <li>
            <a class="index-link" href="#m328">
              Plugins und eingebettete Funktionen sowie Inhalte
            </a>
          </li>
          <li>
            <a class="index-link" href="#m12">
              Löschung von Daten
            </a>
          </li>
          <li>
            <a class="index-link" href="#m15">
              Änderung und Aktualisierung der Datenschutzerklärung
            </a>
          </li>
          <li>
            <a class="index-link" href="#m10">
              Rechte der betroffenen Personen
            </a>
          </li>
          <li>
            <a class="index-link" href="#m42">
              Begriffsdefinitionen
            </a>
          </li>
        </ul>
        <h2 id="m3">Verantwortlicher</h2>
        <p>
          Sebastian Schemmel-Schmähling
          <br />
          Schweppermannstraße 23
          <br />
          90408 Nürnberg
        </p>
        <p>
          E-Mail-Adresse:{" "}
          <a href="mailto:sebastian@mutcho.app">sebastian@mutcho.app</a>
        </p>
        <p>
          Impressum:{" "}
          <a
            href=" https://dev.mutcho.app/de_DE/imprint-and-privacy-notice"
            target="_blank"
          >
            {" "}
            https://dev.mutcho.app/de_DE/imprint-and-privacy-notice
          </a>
        </p>
        <h2 id="mOverview">Übersicht der Verarbeitungen</h2>
        <p>
          Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und
          die Zwecke ihrer Verarbeitung zusammen und verweist auf die
          betroffenen Personen.
        </p>
        <h3>Arten der verarbeiteten Daten</h3>
        <ul>
          <li>Bestandsdaten (z.B. Namen, Adressen).</li>
          <li>Inhaltsdaten (z.B. Eingaben in Onlineformularen).</li>
          <li>Kontaktdaten (z.B. E-Mail, Telefonnummern).</li>
          <li>
            Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
          </li>
          <li>
            Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
            Zugriffszeiten).
          </li>
        </ul>
        <h3>Kategorien betroffener Personen</h3>
        <ul>
          <li>Kommunikationspartner.</li>
          <li>Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).</li>
        </ul>
        <h3>Zwecke der Verarbeitung</h3>
        <ul>
          <li>
            Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.
          </li>
          <li>
            Konversionsmessung (Messung der Effektivität von
            Marketingmaßnahmen).
          </li>
          <li>Content Delivery Network (CDN).</li>
          <li>Direktmarketing (z.B. per E-Mail oder postalisch).</li>
          <li>Feedback (z.B. Sammeln von Feedback via Online-Formular).</li>
          <li>Marketing.</li>
          <li>Kontaktanfragen und Kommunikation.</li>
          <li>
            Profile mit nutzerbezogenen Informationen (Erstellen von
            Nutzerprofilen).
          </li>
          <li>Remarketing.</li>
          <li>Erbringung vertragliche Leistungen und Kundenservice.</li>
          <li>
            Zielgruppenbildung (Bestimmung von für Marketingzwecke relevanten
            Zielgruppen oder sonstige Ausgabe von Inhalten).
          </li>
        </ul>
        <h3 id="m13">Maßgebliche Rechtsgrundlagen</h3>
        <p>
          Im Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen der
          DSGVO, auf deren Basis wir personenbezogenen Daten verarbeiten. Bitte
          nehmen Sie zur Kenntnis, dass neben den Regelungen der DSGVO nationale
          Datenschutzvorgaben in Ihrem bzw. unserem Wohn- oder Sitzland gelten
          können. Sollten ferner im Einzelfall speziellere Rechtsgrundlagen
          maßgeblich sein, teilen wir Ihnen diese in der Datenschutzerklärung
          mit.
        </p>
        <ul>
          <li>
            <strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO)</strong> -
            Die betroffene Person hat ihre Einwilligung in die Verarbeitung der
            sie betreffenden personenbezogenen Daten für einen spezifischen
            Zweck oder mehrere bestimmte Zwecke gegeben.
          </li>
          <li>
            <strong>
              Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1
              lit. b. DSGVO)
            </strong>{" "}
            - Die Verarbeitung ist für die Erfüllung eines Vertrags, dessen
            Vertragspartei die betroffene Person ist, oder zur Durchführung
            vorvertraglicher Maßnahmen erforderlich, die auf Anfrage der
            betroffenen Person erfolgen.
          </li>
          <li>
            <strong>
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO)
            </strong>{" "}
            - Die Verarbeitung ist zur Wahrung der berechtigten Interessen des
            Verantwortlichen oder eines Dritten erforderlich, sofern nicht die
            Interessen oder Grundrechte und Grundfreiheiten der betroffenen
            Person, die den Schutz personenbezogener Daten erfordern,
            überwiegen.
          </li>
        </ul>
        <p>
          <strong>Nationale Datenschutzregelungen in Deutschland</strong>:
          Zusätzlich zu den Datenschutzregelungen der
          Datenschutz-Grundverordnung gelten nationale Regelungen zum
          Datenschutz in Deutschland. Hierzu gehört insbesondere das Gesetz zum
          Schutz vor Missbrauch personenbezogener Daten bei der
          Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält
          insbesondere Spezialregelungen zum Recht auf Auskunft, zum Recht auf
          Löschung, zum Widerspruchsrecht, zur Verarbeitung besonderer
          Kategorien personenbezogener Daten, zur Verarbeitung für andere Zwecke
          und zur Übermittlung sowie automatisierten Entscheidungsfindung im
          Einzelfall einschließlich Profiling. Des Weiteren regelt es die
          Datenverarbeitung für Zwecke des Beschäftigungsverhältnisses (§ 26
          BDSG), insbesondere im Hinblick auf die Begründung, Durchführung oder
          Beendigung von Beschäftigungsverhältnissen sowie die Einwilligung von
          Beschäftigten. Ferner können Landesdatenschutzgesetze der einzelnen
          Bundesländer zur Anwendung gelangen.
        </p>
        <h2 id="m27">Sicherheitsmaßnahmen</h2>
        <p>
          Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter
          Berücksichtigung des Stands der Technik, der Implementierungskosten
          und der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung
          sowie der unterschiedlichen Eintrittswahrscheinlichkeiten und des
          Ausmaßes der Bedrohung der Rechte und Freiheiten natürlicher Personen
          geeignete technische und organisatorische Maßnahmen, um ein dem Risiko
          angemessenes Schutzniveau zu gewährleisten.
        </p>
        <p>
          Zu den Maßnahmen gehören insbesondere die Sicherung der
          Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch
          Kontrolle des physischen und elektronischen Zugangs zu den Daten als
          auch des sie betreffenden Zugriffs, der Eingabe, der Weitergabe, der
          Sicherung der Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir
          Verfahren eingerichtet, die eine Wahrnehmung von Betroffenenrechten,
          die Löschung von Daten und Reaktionen auf die Gefährdung der Daten
          gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener
          Daten bereits bei der Entwicklung bzw. Auswahl von Hardware, Software
          sowie Verfahren entsprechend dem Prinzip des Datenschutzes, durch
          Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.
        </p>
        <p>
          <strong>SSL-Verschlüsselung (https)</strong>: Um Ihre via unser
          Online-Angebot übermittelten Daten zu schützen, nutzen wir eine
          SSL-Verschlüsselung. Sie erkennen derart verschlüsselte Verbindungen
          an dem Präfix https:// in der Adresszeile Ihres Browsers.
        </p>
        <h2 id="m25">Übermittlung von personenbezogenen Daten</h2>
        <p>
          Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt es
          vor, dass die Daten an andere Stellen, Unternehmen, rechtlich
          selbstständige Organisationseinheiten oder Personen übermittelt oder
          sie ihnen gegenüber offengelegt werden. Zu den Empfängern dieser Daten
          können z.B. mit IT-Aufgaben beauftragte Dienstleister oder Anbieter
          von Diensten und Inhalten, die in eine Webseite eingebunden werden,
          gehören. In solchen Fall beachten wir die gesetzlichen Vorgaben und
          schließen insbesondere entsprechende Verträge bzw. Vereinbarungen, die
          dem Schutz Ihrer Daten dienen, mit den Empfängern Ihrer Daten ab.
        </p>
        <h2 id="m24">Datenverarbeitung in Drittländern</h2>
        <p>
          Sofern wir Daten in einem Drittland (d.h., außerhalb der Europäischen
          Union (EU), des Europäischen Wirtschaftsraums (EWR)) verarbeiten oder
          die Verarbeitung im Rahmen der Inanspruchnahme von Diensten Dritter
          oder der Offenlegung bzw. Übermittlung von Daten an andere Personen,
          Stellen oder Unternehmen stattfindet, erfolgt dies nur im Einklang mit
          den gesetzlichen Vorgaben.{" "}
        </p>
        <p>
          Vorbehaltlich ausdrücklicher Einwilligung oder vertraglich oder
          gesetzlich erforderlicher Übermittlung verarbeiten oder lassen wir die
          Daten nur in Drittländern mit einem anerkannten Datenschutzniveau,
          vertraglichen Verpflichtung durch sogenannte Standardschutzklauseln
          der EU-Kommission, beim Vorliegen von Zertifizierungen oder
          verbindlicher internen Datenschutzvorschriften verarbeiten (Art. 44
          bis 49 DSGVO, Informationsseite der EU-Kommission:{" "}
          <a
            href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de"
            target="_blank"
          >
            https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de
          </a>
          ).
        </p>
        <h2 id="m134">Einsatz von Cookies</h2>
        <p>
          Cookies sind Textdateien, die Daten von besuchten Websites oder
          Domains enthalten und von einem Browser auf dem Computer des Benutzers
          gespeichert werden. Ein Cookie dient in erster Linie dazu, die
          Informationen über einen Benutzer während oder nach seinem Besuch
          innerhalb eines Onlineangebotes zu speichern. Zu den gespeicherten
          Angaben können z.B. die Spracheinstellungen auf einer Webseite, der
          Loginstatus, ein Warenkorb oder die Stelle, an der ein Video geschaut
          wurde, gehören. Zu dem Begriff der Cookies zählen wir ferner andere
          Technologien, die die gleichen Funktionen wie Cookies erfüllen (z.B.,
          wenn Angaben der Nutzer anhand pseudonymer Onlinekennzeichnungen
          gespeichert werden, auch als "Nutzer-IDs" bezeichnet)
        </p>
        <p>
          <strong>
            Die folgenden Cookie-Typen und Funktionen werden unterschieden:
          </strong>
        </p>
        <ul>
          <li>
            <strong>
              Temporäre Cookies (auch: Session- oder Sitzungs-Cookies):
            </strong>
            &nbsp;Temporäre Cookies werden spätestens gelöscht, nachdem ein
            Nutzer ein Online-Angebot verlassen und seinen Browser geschlossen
            hat.
          </li>
          <li>
            <strong>Permanente Cookies:</strong>&nbsp;Permanente Cookies bleiben
            auch nach dem Schließen des Browsers gespeichert. So kann
            beispielsweise der Login-Status gespeichert oder bevorzugte Inhalte
            direkt angezeigt werden, wenn der Nutzer eine Website erneut
            besucht. Ebenso können die Interessen von Nutzern, die zur
            Reichweitenmessung oder zu Marketingzwecken verwendet werden, in
            einem solchen Cookie gespeichert werden.
          </li>
          <li>
            <strong>First-Party-Cookies:</strong>&nbsp;First-Party-Cookies
            werden von uns selbst gesetzt.
          </li>
          <li>
            <strong>Third-Party-Cookies (auch: Drittanbieter-Cookies)</strong>:
            Drittanbieter-Cookies werden hauptsächlich von Werbetreibenden (sog.
            Dritten) verwendet, um Benutzerinformationen zu verarbeiten.
          </li>
          <li>
            <strong>
              Notwendige (auch: essentielle oder unbedingt erforderliche)
              Cookies:
            </strong>{" "}
            Cookies können zum einen für den Betrieb einer Webseite unbedingt
            erforderlich sein (z.B. um Logins oder andere Nutzereingaben zu
            speichern oder aus Gründen der Sicherheit).
          </li>
          <li>
            <strong>
              Statistik-, Marketing- und Personalisierungs-Cookies
            </strong>
            : Ferner werden Cookies im Regelfall auch im Rahmen der
            Reichweitenmessung eingesetzt sowie dann, wenn die Interessen eines
            Nutzers oder sein Verhalten (z.B. Betrachten bestimmter Inhalte,
            Nutzen von Funktionen etc.) auf einzelnen Webseiten in einem
            Nutzerprofil gespeichert werden. Solche Profile dienen dazu, den
            Nutzern z.B. Inhalte anzuzeigen, die ihren potentiellen Interessen
            entsprechen. Dieses Verfahren wird auch als "Tracking", d.h.,
            Nachverfolgung der potentiellen Interessen der Nutzer bezeichnet.
            Soweit wir Cookies oder "Tracking"-Technologien einsetzen,
            informieren wir Sie gesondert in unserer Datenschutzerklärung oder
            im Rahmen der Einholung einer Einwilligung.
          </li>
        </ul>
        <p>
          <strong>Hinweise zu Rechtsgrundlagen: </strong> Auf welcher
          Rechtsgrundlage wir Ihre personenbezogenen Daten mit Hilfe von Cookies
          verarbeiten, hängt davon ab, ob wir Sie um eine Einwilligung bitten.
          Falls dies zutrifft und Sie in die Nutzung von Cookies einwilligen,
          ist die Rechtsgrundlage der Verarbeitung Ihrer Daten die erklärte
          Einwilligung. Andernfalls werden die mithilfe von Cookies
          verarbeiteten Daten auf Grundlage unserer berechtigten Interessen
          (z.B. an einem betriebswirtschaftlichen Betrieb unseres
          Onlineangebotes und dessen Verbesserung) verarbeitet oder, wenn der
          Einsatz von Cookies erforderlich ist, um unsere vertraglichen
          Verpflichtungen zu erfüllen.
        </p>
        <p>
          <strong>Speicherdauer: </strong>Sofern wir Ihnen keine expliziten
          Angaben zur Speicherdauer von permanenten Cookies mitteilen (z. B. im
          Rahmen eines sog. Cookie-Opt-Ins), gehen Sie bitte davon aus, dass die
          Speicherdauer bis zu zwei Jahre betragen kann.
        </p>
        <p>
          <strong>
            Allgemeine Hinweise zum Widerruf und Widerspruch (Opt-Out):{" "}
          </strong>{" "}
          Abhängig davon, ob die Verarbeitung auf Grundlage einer Einwilligung
          oder gesetzlichen Erlaubnis erfolgt, haben Sie jederzeit die
          Möglichkeit, eine erteilte Einwilligung zu widerrufen oder der
          Verarbeitung Ihrer Daten durch Cookie-Technologien zu widersprechen
          (zusammenfassend als "Opt-Out" bezeichnet). Sie können Ihren
          Widerspruch zunächst mittels der Einstellungen Ihres Browsers
          erklären, z.B., indem Sie die Nutzung von Cookies deaktivieren (wobei
          hierdurch auch die Funktionsfähigkeit unseres Onlineangebotes
          eingeschränkt werden kann). Ein Widerspruch gegen den Einsatz von
          Cookies zu Zwecken des Onlinemarketings kann auch mittels einer
          Vielzahl von Diensten, vor allem im Fall des Trackings, über die
          Webseiten{" "}
          <a href="https://optout.aboutads.info" target="_blank">
            https://optout.aboutads.info
          </a>{" "}
          und{" "}
          <a href="https://www.youronlinechoices.com/" target="_blank">
            https://www.youronlinechoices.com/
          </a>{" "}
          erklärt werden. Daneben können Sie weitere Widerspruchshinweise im
          Rahmen der Angaben zu den eingesetzten Dienstleistern und Cookies
          erhalten.
        </p>
        <p>
          <strong>
            Verarbeitung von Cookie-Daten auf Grundlage einer Einwilligung
          </strong>
          : Wir setzen ein Verfahren zum Cookie-Einwilligungs-Management ein, in
          dessen Rahmen die Einwilligungen der Nutzer in den Einsatz von
          Cookies, bzw. der im Rahmen des
          Cookie-Einwilligungs-Management-Verfahrens genannten Verarbeitungen
          und Anbieter eingeholt sowie von den Nutzern verwaltet und widerrufen
          werden können. Hierbei wird die Einwilligungserklärung gespeichert, um
          deren Abfrage nicht erneut wiederholen zum müssen und die Einwilligung
          entsprechend der gesetzlichen Verpflichtung nachweisen zu können. Die
          Speicherung kann serverseitig und/oder in einem Cookie (sogenanntes
          Opt-In-Cookie, bzw. mithilfe vergleichbarer Technologien) erfolgen, um
          die Einwilligung einem Nutzer, bzw. dessen Gerät zuordnen zu können.
          Vorbehaltlich individueller Angaben zu den Anbietern von
          Cookie-Management-Diensten, gelten die folgenden Hinweise: Die Dauer
          der Speicherung der Einwilligung kann bis zu zwei Jahren betragen.
          Hierbei wird ein pseudonymer Nutzer-Identifikator gebildet und mit dem
          Zeitpunkt der Einwilligung, Angaben zur Reichweite der Einwilligung
          (z. B. welche Kategorien von Cookies und/oder Diensteanbieter) sowie
          dem Browser, System und verwendeten Endgerät gespeichert.
        </p>
        <ul class="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B.
            besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten),
            Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Nutzer (z.B.
            Webseitenbesucher, Nutzer von Onlinediensten).
          </li>
          <li>
            <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1
            lit. a. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f.
            DSGVO).
          </li>
        </ul>
        <h2 id="m225">Bereitstellung des Onlineangebotes und Webhosting</h2>
        <p>
          Um unser Onlineangebot sicher und effizient bereitstellen zu können,
          nehmen wir die Leistungen von einem oder mehreren Webhosting-Anbietern
          in Anspruch, von deren Servern (bzw. von ihnen verwalteten Servern)
          das Onlineangebot abgerufen werden kann. Zu diesen Zwecken können wir
          Infrastruktur- und Plattformdienstleistungen, Rechenkapazität,
          Speicherplatz und Datenbankdienste sowie Sicherheitsleistungen und
          technische Wartungsleistungen in Anspruch nehmen.
        </p>
        <p>
          Zu den im Rahmen der Bereitstellung des Hostingangebotes verarbeiteten
          Daten können alle die Nutzer unseres Onlineangebotes betreffenden
          Angaben gehören, die im Rahmen der Nutzung und der Kommunikation
          anfallen. Hierzu gehören regelmäßig die IP-Adresse, die notwendig ist,
          um die Inhalte von Onlineangeboten an Browser ausliefern zu können,
          und alle innerhalb unseres Onlineangebotes oder von Webseiten
          getätigten Eingaben.
        </p>
        <p>
          <strong>E-Mail-Versand und -Hosting</strong>: Die von uns in Anspruch
          genommenen Webhosting-Leistungen umfassen ebenfalls den Versand, den
          Empfang sowie die Speicherung von E-Mails. Zu diesen Zwecken werden
          die Adressen der Empfänger sowie Absender als auch weitere
          Informationen betreffend den E-Mailversand (z.B. die beteiligten
          Provider) sowie die Inhalte der jeweiligen E-Mails verarbeitet. Die
          vorgenannten Daten können ferner zu Zwecken der Erkennung von SPAM
          verarbeitet werden. Wir bitten darum, zu beachten, dass E-Mails im
          Internet grundsätzlich nicht verschlüsselt versendet werden. Im
          Regelfall werden E-Mails zwar auf dem Transportweg verschlüsselt, aber
          (sofern kein sogenanntes Ende-zu-Ende-Verschlüsselungsverfahren
          eingesetzt wird) nicht auf den Servern, von denen sie abgesendet und
          empfangen werden. Wir können daher für den Übertragungsweg der E-Mails
          zwischen dem Absender und dem Empfang auf unserem Server keine
          Verantwortung übernehmen.
        </p>
        <p>
          <strong>Erhebung von Zugriffsdaten und Logfiles</strong>: Wir selbst
          (bzw. unser Webhostinganbieter) erheben Daten zu jedem Zugriff auf den
          Server (sogenannte Serverlogfiles). Zu den Serverlogfiles können die
          Adresse und Name der abgerufenen Webseiten und Dateien, Datum und
          Uhrzeit des Abrufs, übertragene Datenmengen, Meldung über
          erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem des
          Nutzers, Referrer URL (die zuvor besuchte Seite) und im Regelfall
          IP-Adressen und der anfragende Provider gehören.
        </p>
        <p>
          Die Serverlogfiles können zum einen zu Zwecken der Sicherheit
          eingesetzt werden, z.B., um eine Überlastung der Server zu vermeiden
          (insbesondere im Fall von missbräuchlichen Angriffen, sogenannten
          DDoS-Attacken) und zum anderen, um die Auslastung der Server und ihre
          Stabilität sicherzustellen.
        </p>
        <p>
          <strong>Content-Delivery-Network</strong>: Wir setzen ein
          "Content-Delivery-Network" (CDN) ein. Ein CDN ist ein Dienst, mit
          dessen Hilfe Inhalte eines Onlineangebotes, insbesondere große
          Mediendateien, wie Grafiken oder Programm-Skripte, mit Hilfe regional
          verteilter und über das Internet verbundener Server schneller und
          sicherer ausgeliefert werden können.
        </p>
        <ul class="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Inhaltsdaten (z.B.
            Eingaben in Onlineformularen), Nutzungsdaten (z.B. besuchte
            Webseiten, Interesse an Inhalten, Zugriffszeiten),
            Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Nutzer (z.B.
            Webseitenbesucher, Nutzer von Onlinediensten).
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres
            Onlineangebotes und Nutzerfreundlichkeit, Content Delivery Network
            (CDN).
          </li>
          <li>
            <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
            Abs. 1 S. 1 lit. f. DSGVO).
          </li>
        </ul>
        <h2 id="m182">Kontaktaufnahme</h2>
        <p>
          Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail,
          Telefon oder via soziale Medien) werden die Angaben der anfragenden
          Personen verarbeitet, soweit dies zur Beantwortung der Kontaktanfragen
          und etwaiger angefragter Maßnahmen erforderlich ist.
        </p>
        <p>
          Die Beantwortung der Kontaktanfragen im Rahmen von vertraglichen oder
          vorvertraglichen Beziehungen erfolgt zur Erfüllung unserer
          vertraglichen Pflichten oder zur Beantwortung von (vor)vertraglichen
          Anfragen und im Übrigen auf Grundlage der berechtigten Interessen an
          der Beantwortung der Anfragen.
        </p>
        <ul class="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
            Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern), Inhaltsdaten
            (z.B. Eingaben in Onlineformularen), Nutzungsdaten (z.B. besuchte
            Webseiten, Interesse an Inhalten, Zugriffszeiten),
            Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Kommunikationspartner.
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und
            Kommunikation.
          </li>
          <li>
            <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
            vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO),
            Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
          </li>
        </ul>
        <h2 id="m17">Newsletter und elektronische Benachrichtigungen</h2>
        <p>
          Wir versenden Newsletter, E-Mails und weitere elektronische
          Benachrichtigungen (nachfolgend "Newsletter“) nur mit der Einwilligung
          der Empfänger oder einer gesetzlichen Erlaubnis. Sofern im Rahmen
          einer Anmeldung zum Newsletter dessen Inhalte konkret umschrieben
          werden, sind sie für die Einwilligung der Nutzer maßgeblich. Im
          Übrigen enthalten unsere Newsletter Informationen zu unseren
          Leistungen und uns.
        </p>
        <p>
          Um sich zu unseren Newslettern anzumelden, reicht es grundsätzlich
          aus, wenn Sie Ihre E-Mail-Adresse angeben. Wir können Sie jedoch
          bitten, einen Namen, zwecks persönlicher Ansprache im Newsletter, oder
          weitere Angaben, sofern diese für die Zwecke des Newsletters
          erforderlich sind, zu tätigen.
        </p>
        <p>
          <strong>Double-Opt-In-Verfahren:</strong> Die Anmeldung zu unserem
          Newsletter erfolgt grundsätzlich in einem sogenannte
          Double-Opt-In-Verfahren. D.h., Sie erhalten nach der Anmeldung eine
          E-Mail, in der Sie um die Bestätigung Ihrer Anmeldung gebeten werden.
          Diese Bestätigung ist notwendig, damit sich niemand mit fremden
          E-Mail-Adressen anmelden kann. Die Anmeldungen zum Newsletter werden
          protokolliert, um den Anmeldeprozess entsprechend den rechtlichen
          Anforderungen nachweisen zu können. Hierzu gehört die Speicherung des
          Anmelde- und des Bestätigungszeitpunkts als auch der IP-Adresse.
          Ebenso werden die Änderungen Ihrer bei dem Versanddienstleister
          gespeicherten Daten protokolliert.
        </p>
        <p>
          <strong>Löschung und Einschränkung der Verarbeitung: </strong> Wir
          können die ausgetragenen E-Mail-Adressen bis zu drei Jahren auf
          Grundlage unserer berechtigten Interessen speichern, bevor wir sie
          löschen, um eine ehemals gegebene Einwilligung nachweisen zu können.
          Die Verarbeitung dieser Daten wird auf den Zweck einer möglichen
          Abwehr von Ansprüchen beschränkt. Ein individueller Löschungsantrag
          ist jederzeit möglich, sofern zugleich das ehemalige Bestehen einer
          Einwilligung bestätigt wird. Im Fall von Pflichten zur dauerhaften
          Beachtung von Widersprüchen behalten wir uns die Speicherung der
          E-Mail-Adresse alleine zu diesem Zweck in einer Sperrliste (sogenannte
          "Blocklist") vor.
        </p>
        <p>
          Die Protokollierung des Anmeldeverfahrens erfolgt auf Grundlage
          unserer berechtigten Interessen zu Zwecken des Nachweises seines
          ordnungsgemäßen Ablaufs. Soweit wir einen Dienstleister mit dem
          Versand von E-Mails beauftragen, erfolgt dies auf Grundlage unserer
          berechtigten Interessen an einem effizienten und sicheren
          Versandsystem.
        </p>
        <p>
          <strong>Hinweise zu Rechtsgrundlagen:</strong> Der Versand der
          Newsletter erfolgt auf Grundlage einer Einwilligung der Empfänger
          oder, falls eine Einwilligung nicht erforderlich ist, auf Grundlage
          unserer berechtigten Interessen am Direktmarketing, sofern und soweit
          diese gesetzlich, z.B. im Fall von Bestandskundenwerbung, erlaubt ist.
          Soweit wir einen Dienstleister mit dem Versand von E-Mails
          beauftragen, geschieht dies auf der Grundlage unserer berechtigten
          Interessen. Das Registrierungsverfahren wird auf der Grundlage unserer
          berechtigten Interessen aufgezeichnet, um nachzuweisen, dass es in
          Übereinstimmung mit dem Gesetz durchgeführt wurde.
        </p>
        <p>
          Inhalte: Informationen zu uns, unseren Leistungen, Aktionen und
          Angeboten.
        </p>
        <ul class="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
            Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern),
            Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Kommunikationspartner.
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Direktmarketing (z.B. per
            E-Mail oder postalisch).
          </li>
          <li>
            <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1
            lit. a. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f.
            DSGVO).
          </li>
          <li>
            <strong>Widerspruchsmöglichkeit (Opt-Out):</strong> Sie können den
            Empfang unseres Newsletters jederzeit kündigen, d.h. Ihre
            Einwilligungen widerrufen, bzw. dem weiteren Empfang widersprechen.
            Einen Link zur Kündigung des Newsletters finden Sie entweder am Ende
            eines jeden Newsletters oder können sonst eine der oben angegebenen
            Kontaktmöglichkeiten, vorzugswürdig E-Mail, hierzu nutzen.
          </li>
        </ul>
        <h2 id="m638">
          Werbliche Kommunikation via E-Mail, Post, Fax oder Telefon
        </h2>
        <p>
          Wir verarbeiten personenbezogene Daten zu Zwecken der werblichen
          Kommunikation, die über diverse Kanäle, wie z.B. E-Mail, Telefon, Post
          oder Fax, entsprechend den gesetzlichen Vorgaben erfolgen kann.
        </p>
        <p>
          Die Empfänger haben das Recht, erteilte Einwilligungen jederzeit zu
          widerrufen oder der werblichen Kommunikation jederzeit zu
          widersprechen.
        </p>
        <p>
          Nach Widerruf bzw. Widerspruch können wir die zum Nachweis der
          Einwilligung erforderlichen Daten bis zu drei Jahren auf Grundlage
          unserer berechtigten Interessen speichern, bevor wir sie löschen. Die
          Verarbeitung dieser Daten wird auf den Zweck einer möglichen Abwehr
          von Ansprüchen beschränkt. Ein individueller Löschungsantrag ist
          jederzeit möglich, sofern zugleich das ehemalige Bestehen einer
          Einwilligung bestätigt wird.
        </p>
        <ul class="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
            Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Kommunikationspartner.
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Direktmarketing (z.B. per
            E-Mail oder postalisch).
          </li>
          <li>
            <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1
            lit. a. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f.
            DSGVO).
          </li>
        </ul>
        <h2 id="m264">Onlinemarketing</h2>
        <p>
          Wir verarbeiten personenbezogene Daten zu Zwecken des
          Onlinemarketings, worunter insbesondere die Vermarktung von
          Werbeflächen oder Darstellung von werbenden und sonstigen Inhalten
          (zusammenfassend als "Inhalte" bezeichnet) anhand potentieller
          Interessen der Nutzer sowie die Messung ihrer Effektivität fallen
          kann.{" "}
        </p>
        <p>
          Zu diesen Zwecken werden sogenannte Nutzerprofile angelegt und in
          einer Datei (sogenannte "Cookie") gespeichert oder ähnliche Verfahren
          genutzt, mittels derer die für die Darstellung der vorgenannten
          Inhalte relevante Angaben zum Nutzer gespeichert werden. Zu diesen
          Angaben können z.B. betrachtete Inhalte, besuchte Webseiten, genutzte
          Onlinenetzwerke, aber auch Kommunikationspartner und technische
          Angaben, wie der verwendete Browser, das verwendete Computersystem
          sowie Angaben zu Nutzungszeiten gehören. Sofern Nutzer in die Erhebung
          ihrer Standortdaten eingewilligt haben, können auch diese verarbeitet
          werden.
        </p>
        <p>
          Es werden ebenfalls die IP-Adressen der Nutzer gespeichert. Jedoch
          nutzen wir zur Verfügung stehende IP-Masking-Verfahren (d.h.,
          Pseudonymisierung durch Kürzung der IP-Adresse) zum Schutz der Nutzer.
          Generell werden im Rahmen des Onlinemarketingverfahren keine Klardaten
          der Nutzer (wie z.B. E-Mail-Adressen oder Namen) gespeichert, sondern
          Pseudonyme. D.h., wir als auch die Anbieter der
          Onlinemarketingverfahren kennen nicht die tatsächlich Identität der
          Nutzer, sondern nur die in deren Profilen gespeicherten Angaben.
        </p>
        <p>
          Die Angaben in den Profilen werden im Regelfall in den Cookies oder
          mittels ähnlicher Verfahren gespeichert. Diese Cookies können später
          generell auch auf anderen Webseiten die dasselbe
          Onlinemarketingverfahren einsetzen, ausgelesen und zu Zwecken der
          Darstellung von Inhalten analysiert als auch mit weiteren Daten
          ergänzt und auf dem Server des Onlinemarketingverfahrensanbieters
          gespeichert werden.
        </p>
        <p>
          Ausnahmsweise können Klardaten den Profilen zugeordnet werden. Das ist
          der Fall, wenn die Nutzer z.B. Mitglieder eines sozialen Netzwerks
          sind, dessen Onlinemarketingverfahren wir einsetzen und das Netzwerk
          die Profile der Nutzer mit den vorgenannten Angaben verbindet. Wir
          bitten darum, zu beachten, dass Nutzer mit den Anbietern zusätzliche
          Abreden, z.B. durch Einwilligung im Rahmen der Registrierung, treffen
          können.
        </p>
        <p>
          Wir erhalten grundsätzlich nur Zugang zu zusammengefassten
          Informationen über den Erfolg unserer Werbeanzeigen. Jedoch können wir
          im Rahmen sogenannter Konversionsmessungen prüfen, welche unserer
          Onlinemarketingverfahren zu einer sogenannten Konversion geführt
          haben, d.h. z.B., zu einem Vertragsschluss mit uns. Die
          Konversionsmessung wird alleine zur Analyse des Erfolgs unserer
          Marketingmaßnahmen verwendet.
        </p>
        <p>
          Solange nicht anders angegeben, bitten wir Sie davon auszugehen, dass
          verwendete Cookies für einen Zeitraum von zwei Jahren gespeichert
          werden.
        </p>
        <p>
          <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Nutzer
          um deren Einwilligung in den Einsatz der Drittanbieter bitten, ist die
          Rechtsgrundlage der Verarbeitung von Daten die Einwilligung. Ansonsten
          werden die Daten der Nutzer auf Grundlage unserer berechtigten
          Interessen (d.h. Interesse an effizienten, wirtschaftlichen und
          empfängerfreundlichen Leistungen) verarbeitet. In diesem Zusammenhang
          möchten wir Sie auch auf die Informationen zur Verwendung von Cookies
          in dieser Datenschutzerklärung hinweisen.
        </p>
        <p>
          <strong>Zielgruppenbildung mit Google Analytics</strong>: Wir setzen
          Google Analytics ein, um die durch innerhalb von Werbediensten Googles
          und seiner Partner geschalteten Anzeigen, nur solchen Nutzern
          anzuzeigen, die auch ein Interesse an unserem Onlineangebot gezeigt
          haben oder die bestimmte Merkmale (z.B. Interessen an bestimmten
          Themen oder Produkten, die anhand der besuchten Webseiten bestimmt
          werden) aufweisen, die wir an Google übermitteln (sog. "Remarketing-“,
          bzw. "Google-Analytics-Audiences“). Mit Hilfe der Remarketing
          Audiences möchten wir auch sicherstellen, dass unsere Anzeigen dem
          potentiellen Interesse der Nutzer entsprechen
        </p>
        <ul class="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B.
            besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten),
            Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Nutzer (z.B.
            Webseitenbesucher, Nutzer von Onlinediensten).
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Marketing, Profile mit
            nutzerbezogenen Informationen (Erstellen von Nutzerprofilen),
            Remarketing, Zielgruppenbildung (Bestimmung von für Marketingzwecke
            relevanten Zielgruppen oder sonstige Ausgabe von Inhalten),
            Konversionsmessung (Messung der Effektivität von
            Marketingmaßnahmen).
          </li>
          <li>
            <strong>Sicherheitsmaßnahmen:</strong> IP-Masking (Pseudonymisierung
            der IP-Adresse).
          </li>
          <li>
            <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1
            lit. a. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f.
            DSGVO).
          </li>
          <li>
            <strong>Widerspruchsmöglichkeit (Opt-Out):</strong> Wir verweisen
            auf die Datenschutzhinweise der jeweiligen Anbieter und die zu den
            Anbietern angegebenen Widerspruchsmöglichkeiten (sog. "Opt-Out").
            Sofern keine explizite Opt-Out-Möglichkeit angegeben wurde, besteht
            zum einen die Möglichkeit, dass Sie Cookies in den Einstellungen
            Ihres Browsers abschalten. Hierdurch können jedoch Funktionen
            unseres Onlineangebotes eingeschränkt werden. Wir empfehlen daher
            zusätzlich die folgenden Opt-Out-Möglichkeiten, die zusammenfassend
            auf jeweilige Gebiete gerichtet angeboten werden: a) Europa:{" "}
            <a href="https://www.youronlinechoices.eu" target="_blank">
              https://www.youronlinechoices.eu
            </a>
            . b) Kanada:{" "}
            <a href="https://www.youradchoices.ca/choices" target="_blank">
              https://www.youradchoices.ca/choices
            </a>
            . c) USA:{" "}
            <a href="https://www.aboutads.info/choices" target="_blank">
              https://www.aboutads.info/choices
            </a>
            . d) Gebietsübergreifend:{" "}
            <a href="https://optout.aboutads.info" target="_blank">
              https://optout.aboutads.info
            </a>
            .
          </li>
        </ul>
        <p>
          <strong>Eingesetzte Dienste und Diensteanbieter:</strong>
        </p>
        <ul class="m-elements">
          <li>
            <strong>Google Tag Manager:</strong> Google Tag Manager ist eine
            Lösung, mit der wir sog. Website-Tags über eine Oberfläche verwalten
            und so andere Dienste in unser Onlineangebot einbinden können
            (hierzu wird auf weitere Angaben in dieser Datenschutzerklärung
            verwiesen). Mit dem Tag Manager selbst (welches die Tags
            implementiert) werden daher z. B. noch keine Profile der Nutzer
            erstellt oder Cookies gespeichert. Google erfährt lediglich die
            IP-Adresse des Nutzers, was notwendig ist, um den Google Tag Manager
            auszuführen. Dienstanbieter: Google Ireland Limited, Gordon House,
            Barrow Street, Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600
            Amphitheatre Parkway, Mountain View, CA 94043, USA; Website:{" "}
            <a href="https://marketingplatform.google.com" target="_blank">
              https://marketingplatform.google.com
            </a>
            ; Datenschutzerklärung:{" "}
            <a href="https://policies.google.com/privacy" target="_blank">
              https://policies.google.com/privacy
            </a>
            .
          </li>
          <li>
            <strong>Google Analytics:</strong> Onlinemarketing und Webanalyse;
            Dienstanbieter: Google Ireland Limited, Gordon House, Barrow Street,
            Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600 Amphitheatre
            Parkway, Mountain View, CA 94043, USA; Website:{" "}
            <a
              href="https://marketingplatform.google.com/intl/de/about/analytics/"
              target="_blank"
            >
              https://marketingplatform.google.com/intl/de/about/analytics/
            </a>
            ; Datenschutzerklärung:{" "}
            <a href="https://policies.google.com/privacy" target="_blank">
              https://policies.google.com/privacy
            </a>
            ; Widerspruchsmöglichkeit (Opt-Out): Opt-Out-Plugin:{" "}
            <a
              href="https://tools.google.com/dlpage/gaoptout?hl=de"
              target="_blank"
            >
              https://tools.google.com/dlpage/gaoptout?hl=de
            </a>
            , Einstellungen für die Darstellung von Werbeeinblendungen:{" "}
            <a
              href="https://adssettings.google.com/authenticated"
              target="_blank"
            >
              https://adssettings.google.com/authenticated
            </a>
            .
          </li>
          <li>
            <strong>Zielgruppenbildung mit Google Analytics:</strong> .
            Dienstanbieter: Google Ireland Limited, Gordon House, Barrow Street,
            Dublin 4, Ireland, parent company: Google LLC, 1600 Amphitheatre
            Parkway, Mountain View, CA 94043, USA; Website:{" "}
            <a href="https://marketingplatform.google.com" target="_blank">
              https://marketingplatform.google.com
            </a>
            ; Datenschutzerklärung:{" "}
            <a href="https://policies.google.com/privacy" target="_blank">
              https://policies.google.com/privacy
            </a>
            .
          </li>
          <li>
            <strong>Google Ads und Konversionsmessung:</strong> Wir nutzen das
            Onlinemarketingverfahren "Google Ads", um Anzeigen im
            Google-Werbe-Netzwerk zu platzieren (z.B., in Suchergebnissen, in
            Videos, auf Webseiten, etc.), damit sie Nutzern angezeigt werden,
            die ein mutmaßliches Interesse an den Anzeigen haben. Ferner messen
            wir die Konversion der Anzeigen. Wir erfahren jedoch nur die anonyme
            Gesamtanzahl der Nutzer, die auf unsere Anzeige geklickt haben und
            zu einer mit einem sog "Conversion-Tracking-Tag" versehenen Seite
            weitergeleitet wurden. Wir selbst erhalten jedoch keine
            Informationen, mit denen sich Nutzer identifizieren lassen.
            Dienstanbieter: Google Ireland Limited, Gordon House, Barrow Street,
            Dublin 4, Ireland, parent company: Google LLC, 1600 Amphitheatre
            Parkway, Mountain View, CA 94043, USA; Website:{" "}
            <a href="https://marketingplatform.google.com" target="_blank">
              https://marketingplatform.google.com
            </a>
            ; Datenschutzerklärung:{" "}
            <a href="https://policies.google.com/privacy" target="_blank">
              https://policies.google.com/privacy
            </a>
            .
          </li>
          <li>
            <strong>Google Ad Manager:</strong> Wir nutzen die "Google Marketing
            Platform" (und Dienste wie z.B. "Google Ad Manager"), um Anzeigen im
            Google-Werbe-Netzwerk zu platzieren (z.B., in Suchergebnissen, in
            Videos, auf Webseiten, etc.). Die Google Marketing Platform zeichnet
            sich dadurch aus, dass Anzeigen in Echtzeit anhand mutmaßlicher
            Interessen der Nutzer angezeigt werden. Dies erlaubt uns Anzeigen
            für und innerhalb unseres Onlineangebotes gezielter anzuzeigen, um
            Nutzern nur Anzeigen zu präsentieren, die potentiell deren
            Interessen entsprechen. Falls einem Nutzer z.B. Anzeigen für
            Produkte angezeigt werden, für die er sich auf anderen
            Onlineangeboten interessiert hat, spricht man hierbei vom
            "Remarketing“. Dienstanbieter: Google Ireland Limited, Gordon House,
            Barrow Street, Dublin 4, Ireland, parent company: Google LLC, 1600
            Amphitheatre Parkway, Mountain View, CA 94043, USA; Website:{" "}
            <a href="https://marketingplatform.google.com" target="_blank">
              https://marketingplatform.google.com
            </a>
            ; Datenschutzerklärung:{" "}
            <a href="https://policies.google.com/privacy" target="_blank">
              https://policies.google.com/privacy
            </a>
            .
          </li>
          <li>
            <strong>Google Adsense mit personalisierten Anzeigen:</strong> Wir
            nutzen den Dienst Google Adsense mit personalisierten Anzeigen, mit
            dessen Hilfe innerhalb unseres Onlineangebotes Anzeigen eingeblendet
            werden und wir für deren Einblendung oder sonstige Nutzung eine
            Entlohnung erhalten. ; Dienstanbieter: Google Ireland Limited,
            Gordon House, Barrow Street, Dublin 4, Ireland, parent company:
            Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA;
            Website:{" "}
            <a href="https://marketingplatform.google.com" target="_blank">
              https://marketingplatform.google.com
            </a>
            ; Datenschutzerklärung:{" "}
            <a href="https://policies.google.com/privacy" target="_blank">
              https://policies.google.com/privacy
            </a>
            .
          </li>
          <li>
            <strong>Google Adsense mit nicht-personalisierten Anzeigen:</strong>{" "}
            Wir nutzen den Dienst Google Adsense mit nicht-personalisierten
            Anzeigen, mit dessen Hilfe innerhalb unseres Onlineangebotes
            Anzeigen eingeblendet werden und wir für deren Einblendung oder
            sonstige Nutzung eine Entlohnung erhalten. ; Dienstanbieter: Google
            Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland,
            parent company: Google LLC, 1600 Amphitheatre Parkway, Mountain
            View, CA 94043, USA; Website:{" "}
            <a href="https://marketingplatform.google.com" target="_blank">
              https://marketingplatform.google.com
            </a>
            ; Datenschutzerklärung:{" "}
            <a href="https://policies.google.com/privacy" target="_blank">
              https://policies.google.com/privacy
            </a>
            .
          </li>
        </ul>
        <h2 id="m136">Präsenzen in sozialen Netzwerken (Social Media)</h2>
        <p>
          Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und
          verarbeiten in diesem Rahmen Daten der Nutzer, um mit den dort aktiven
          Nutzern zu kommunizieren oder um Informationen über uns anzubieten.
        </p>
        <p>
          Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb des
          Raumes der Europäischen Union verarbeitet werden können. Hierdurch
          können sich für die Nutzer Risiken ergeben, weil so z.B. die
          Durchsetzung der Rechte der Nutzer erschwert werden könnte.
        </p>
        <p>
          Ferner werden die Daten der Nutzer innerhalb sozialer Netzwerke im
          Regelfall für Marktforschungs- und Werbezwecke verarbeitet. So können
          z.B. anhand des Nutzungsverhaltens und sich daraus ergebender
          Interessen der Nutzer Nutzungsprofile erstellt werden. Die
          Nutzungsprofile können wiederum verwendet werden, um z.B.
          Werbeanzeigen innerhalb und außerhalb der Netzwerke zu schalten, die
          mutmaßlich den Interessen der Nutzer entsprechen. Zu diesen Zwecken
          werden im Regelfall Cookies auf den Rechnern der Nutzer gespeichert,
          in denen das Nutzungsverhalten und die Interessen der Nutzer
          gespeichert werden. Ferner können in den Nutzungsprofilen auch Daten
          unabhängig der von den Nutzern verwendeten Geräte gespeichert werden
          (insbesondere, wenn die Nutzer Mitglieder der jeweiligen Plattformen
          sind und bei diesen eingeloggt sind).
        </p>
        <p>
          Für eine detaillierte Darstellung der jeweiligen Verarbeitungsformen
          und der Widerspruchsmöglichkeiten (Opt-Out) verweisen wir auf die
          Datenschutzerklärungen und Angaben der Betreiber der jeweiligen
          Netzwerke.
        </p>
        <p>
          Auch im Fall von Auskunftsanfragen und der Geltendmachung von
          Betroffenenrechten weisen wir darauf hin, dass diese am effektivsten
          bei den Anbietern geltend gemacht werden können. Nur die Anbieter
          haben jeweils Zugriff auf die Daten der Nutzer und können direkt
          entsprechende Maßnahmen ergreifen und Auskünfte geben. Sollten Sie
          dennoch Hilfe benötigen, dann können Sie sich an uns wenden.
        </p>
        <p>
          <strong>Facebook</strong>: Wir sind gemeinsam mit Facebook Irland Ltd.
          für die Erhebung (jedoch nicht die weitere Verarbeitung) von Daten der
          Besucher unserer Facebook-Seite (sog. "Fanpage") verantwortlich. Zu
          diesen Daten gehören Informationen zu den Arten von Inhalten, die
          Nutzer sich ansehen oder mit denen sie interagieren, oder die von
          ihnen vorgenommenen Handlungen (siehe unter „Von dir und anderen
          getätigte und bereitgestellte Dinge“ in der Facebook-Datenrichtlinie:{" "}
          <a href="https://www.facebook.com/policy" target="_blank">
            https://www.facebook.com/policy
          </a>
          ), sowie Informationen über die von den Nutzern genutzten Geräte (z.
          B. IP-Adressen, Betriebssystem, Browsertyp, Spracheinstellungen,
          Cookie-Daten; siehe unter „Geräteinformationen“ in der
          Facebook-Datenrichtlinie-erklärung:{" "}
          <a href="https://www.facebook.com/policy" target="_blank">
            https://www.facebook.com/policy
          </a>
          ). Wie in der Facebook-Datenrichtlinie unter „Wie verwenden wir diese
          Informationen?“ erläutert, erhebt und verwendet Facebook Informationen
          auch, um Analysedienste, so genannte "Seiten-Insights", für
          Seitenbetreiber bereitzustellen, damit diese Erkenntnisse darüber
          erhalten, wie Personen mit ihren Seiten und mit den mit ihnen
          verbundenen Inhalten interagieren. Wir haben mit Facebook eine
          spezielle Vereinbarung abgeschlossen ("Informationen zu
          Seiten-Insights",{" "}
          <a
            href="https://www.facebook.com/legal/terms/page_controller_addendum"
            target="_blank"
          >
            https://www.facebook.com/legal/terms/page_controller_addendum
          </a>
          ), in der insbesondere geregelt wird, welche Sicherheitsmaßnahmen
          Facebook beachten muss und in der Facebook sich bereit erklärt hat die
          Betroffenenrechte zu erfüllen (d. h. Nutzer können z. B. Auskünfte
          oder Löschungsanfragen direkt an Facebook richten). Die Rechte der
          Nutzer (insbesondere auf Auskunft, Löschung, Widerspruch und
          Beschwerde bei zuständiger Aufsichtsbehörde), werden durch die
          Vereinbarungen mit Facebook nicht eingeschränkt. Weitere Hinweise
          finden sich in den "Informationen zu Seiten-Insights" (
          <a
            href="https://www.facebook.com/legal/terms/information_about_page_insights_data"
            target="_blank"
          >
            https://www.facebook.com/legal/terms/information_about_page_insights_data
          </a>
          ).
        </p>
        <ul class="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z.B. E-Mail,
            Telefonnummern), Inhaltsdaten (z.B. Eingaben in Onlineformularen),
            Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
            Zugriffszeiten), Meta-/Kommunikationsdaten (z.B.
            Geräte-Informationen, IP-Adressen).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Nutzer (z.B.
            Webseitenbesucher, Nutzer von Onlinediensten).
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und
            Kommunikation, Feedback (z.B. Sammeln von Feedback via
            Online-Formular), Marketing.
          </li>
          <li>
            <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
            Abs. 1 S. 1 lit. f. DSGVO).
          </li>
        </ul>
        <p>
          <strong>Eingesetzte Dienste und Diensteanbieter:</strong>
        </p>
        <ul class="m-elements">
          <li>
            <strong>Instagram:</strong> Soziales Netzwerk; Dienstanbieter:
            Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025, USA,
            Mutterunternehmen: Facebook, 1 Hacker Way, Menlo Park, CA 94025,
            USA; Website:{" "}
            <a href="https://www.instagram.com" target="_blank">
              https://www.instagram.com
            </a>
            ; Datenschutzerklärung:{" "}
            <a href="https://instagram.com/about/legal/privacy" target="_blank">
              https://instagram.com/about/legal/privacy
            </a>
            .
          </li>
          <li>
            <strong>Facebook:</strong> Soziales Netzwerk; Dienstanbieter:
            Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour,
            Dublin 2, Irland, Mutterunternehmen: Facebook, 1 Hacker Way, Menlo
            Park, CA 94025, USA; Website:{" "}
            <a href="https://www.facebook.com" target="_blank">
              https://www.facebook.com
            </a>
            ; Datenschutzerklärung:{" "}
            <a href="https://www.facebook.com/about/privacy" target="_blank">
              https://www.facebook.com/about/privacy
            </a>
            ; Widerspruchsmöglichkeit (Opt-Out): Einstellungen für
            Werbeanzeigen:{" "}
            <a href="https://www.facebook.com/settings?tab=ads" target="_blank">
              https://www.facebook.com/settings?tab=ads
            </a>
            .
          </li>
          <li>
            <strong>LinkedIn:</strong> Soziales Netzwerk; Dienstanbieter:
            LinkedIn Ireland Unlimited Company, Wilton Place, Dublin 2, Irland;
            Website:{" "}
            <a href="https://www.linkedin.com" target="_blank">
              https://www.linkedin.com
            </a>
            ; Datenschutzerklärung:{" "}
            <a
              href="https://www.linkedin.com/legal/privacy-policy"
              target="_blank"
            >
              https://www.linkedin.com/legal/privacy-policy
            </a>
            ; Widerspruchsmöglichkeit (Opt-Out):{" "}
            <a
              href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"
              target="_blank"
            >
              https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out
            </a>
            .
          </li>
          <li>
            <strong>TikTok:</strong> Soziales Netzwerk / Video-Plattform;
            Dienstanbieter: musical.ly Inc., 10351 Santa Monica Blvd #310, Los
            Angeles, CA 90025 USA; Website:{" "}
            <a href="https://www.tiktok.com" target="_blank">
              https://www.tiktok.com
            </a>
            ; Datenschutzerklärung:{" "}
            <a href="https://www.tiktok.com/de/privacy-policy" target="_blank">
              https://www.tiktok.com/de/privacy-policy
            </a>
            .
          </li>
          <li>
            <strong>Twitter:</strong> Soziales Netzwerk; Dienstanbieter: Twitter
            International Company, One Cumberland Place, Fenian Street, Dublin 2
            D02 AX07, Irland, Mutterunternehmen: Twitter Inc., 1355 Market
            Street, Suite 900, San Francisco, CA 94103, USA;
            Datenschutzerklärung:{" "}
            <a href="https://twitter.com/de/privacy" target="_blank">
              https://twitter.com/de/privacy
            </a>
            , (Einstellungen){" "}
            <a href="https://twitter.com/personalization" target="_blank">
              https://twitter.com/personalization
            </a>
            .
          </li>
          <li>
            <strong>YouTube:</strong> Soziales Netzwerk und Videoplattform;
            Dienstanbieter: Google Ireland Limited, Gordon House, Barrow Street,
            Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600 Amphitheatre
            Parkway, Mountain View, CA 94043, USA; Datenschutzerklärung:{" "}
            <a href="https://policies.google.com/privacy" target="_blank">
              https://policies.google.com/privacy
            </a>
            ; Widerspruchsmöglichkeit (Opt-Out):{" "}
            <a
              href="https://adssettings.google.com/authenticated"
              target="_blank"
            >
              https://adssettings.google.com/authenticated
            </a>
            .
          </li>
        </ul>
        <h2 id="m328">Plugins und eingebettete Funktionen sowie Inhalte</h2>
        <p>
          Wir binden in unser Onlineangebot Funktions- und Inhaltselemente ein,
          die von den Servern ihrer jeweiligen Anbieter (nachfolgend bezeichnet
          als "Drittanbieter”) bezogen werden. Dabei kann es sich zum Beispiel
          um Grafiken, Videos oder Stadtpläne handeln (nachfolgend einheitlich
          bezeichnet als "Inhalte”).
        </p>
        <p>
          Die Einbindung setzt immer voraus, dass die Drittanbieter dieser
          Inhalte die IP-Adresse der Nutzer verarbeiten, da sie ohne die
          IP-Adresse die Inhalte nicht an deren Browser senden könnten. Die
          IP-Adresse ist damit für die Darstellung dieser Inhalte oder
          Funktionen erforderlich. Wir bemühen uns, nur solche Inhalte zu
          verwenden, deren jeweilige Anbieter die IP-Adresse lediglich zur
          Auslieferung der Inhalte verwenden. Drittanbieter können ferner
          sogenannte Pixel-Tags (unsichtbare Grafiken, auch als "Web Beacons"
          bezeichnet) für statistische oder Marketingzwecke verwenden. Durch die
          "Pixel-Tags" können Informationen, wie der Besucherverkehr auf den
          Seiten dieser Webseite, ausgewertet werden. Die pseudonymen
          Informationen können ferner in Cookies auf dem Gerät der Nutzer
          gespeichert werden und unter anderem technische Informationen zum
          Browser und zum Betriebssystem, zu verweisenden Webseiten, zur
          Besuchszeit sowie weitere Angaben zur Nutzung unseres Onlineangebotes
          enthalten als auch mit solchen Informationen aus anderen Quellen
          verbunden werden.
        </p>
        <p>
          <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Nutzer
          um deren Einwilligung in den Einsatz der Drittanbieter bitten, ist die
          Rechtsgrundlage der Verarbeitung von Daten die Einwilligung. Ansonsten
          werden die Daten der Nutzer auf Grundlage unserer berechtigten
          Interessen (d.h. Interesse an effizienten, wirtschaftlichen und
          empfängerfreundlichen Leistungen) verarbeitet. In diesem Zusammenhang
          möchten wir Sie auch auf die Informationen zur Verwendung von Cookies
          in dieser Datenschutzerklärung hinweisen.
        </p>
        <p>Wir setzen zusätzlich reCAPTCHA ein.</p>
        <ul class="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B.
            besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten),
            Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen),
            Bestandsdaten (z.B. Namen, Adressen), Kontaktdaten (z.B. E-Mail,
            Telefonnummern), Inhaltsdaten (z.B. Eingaben in Onlineformularen).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Nutzer (z.B.
            Webseitenbesucher, Nutzer von Onlinediensten).
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres
            Onlineangebotes und Nutzerfreundlichkeit, Erbringung vertragliche
            Leistungen und Kundenservice.
          </li>
          <li>
            <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
            Abs. 1 S. 1 lit. f. DSGVO), Einwilligung (Art. 6 Abs. 1 S. 1 lit. a.
            DSGVO), Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs.
            1 S. 1 lit. b. DSGVO).
          </li>
        </ul>
        <p>
          <strong>Eingesetzte Dienste und Diensteanbieter:</strong>
        </p>
        <ul class="m-elements">
          <li>
            <strong>Google Fonts:</strong> Wir binden die Schriftarten ("Google
            Fonts") des Anbieters Google ein, wobei die Daten der Nutzer allein
            zu Zwecken der Darstellung der Schriftarten im Browser der Nutzer
            verwendet werden. Die Einbindung erfolgt auf Grundlage unserer
            berechtigten Interessen an einer technisch sicheren, wartungsfreien
            und effizienten Nutzung von Schriftarten, deren einheitlicher
            Darstellung sowie unter Berücksichtigung möglicher lizenzrechtlicher
            Restriktionen für deren Einbindung. Dienstanbieter: Google Ireland
            Limited, Gordon House, Barrow Street, Dublin 4, Irland,
            Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain
            View, CA 94043, USA; Website:{" "}
            <a href="https://fonts.google.com/" target="_blank">
              https://fonts.google.com/
            </a>
            ; Datenschutzerklärung:{" "}
            <a href="https://policies.google.com/privacy" target="_blank">
              https://policies.google.com/privacy
            </a>
            .
          </li>
          <li>
            <strong>YouTube-Videos:</strong> Videoinhalte; Dienstanbieter:
            Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
            Irland, Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway,
            Mountain View, CA 94043, USA; Website:{" "}
            <a href="https://www.youtube.com" target="_blank">
              https://www.youtube.com
            </a>
            ; Datenschutzerklärung:{" "}
            <a href="https://policies.google.com/privacy" target="_blank">
              https://policies.google.com/privacy
            </a>
            ; Widerspruchsmöglichkeit (Opt-Out): Opt-Out-Plugin:{" "}
            <a
              href="https://tools.google.com/dlpage/gaoptout?hl=de"
              target="_blank"
            >
              https://tools.google.com/dlpage/gaoptout?hl=de
            </a>
            , Einstellungen für die Darstellung von Werbeeinblendungen:{" "}
            <a
              href="https://adssettings.google.com/authenticated"
              target="_blank"
            >
              https://adssettings.google.com/authenticated
            </a>
            .
          </li>
        </ul>
        <h2 id="m12">Löschung von Daten</h2>
        <p>
          Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen
          Vorgaben gelöscht, sobald deren zur Verarbeitung erlaubten
          Einwilligungen widerrufen werden oder sonstige Erlaubnisse entfallen
          (z.B., wenn der Zweck der Verarbeitung dieser Daten entfallen ist oder
          sie für den Zweck nicht erforderlich sind).
        </p>
        <p>
          Sofern die Daten nicht gelöscht werden, weil sie für andere und
          gesetzlich zulässige Zwecke erforderlich sind, wird deren Verarbeitung
          auf diese Zwecke beschränkt. D.h., die Daten werden gesperrt und nicht
          für andere Zwecke verarbeitet. Das gilt z.B. für Daten, die aus
          handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen oder
          deren Speicherung zur Geltendmachung, Ausübung oder Verteidigung von
          Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen
          oder juristischen Person erforderlich ist.
        </p>
        <p>
          Unsere Datenschutzhinweise können ferner weitere Angaben zu der
          Aufbewahrung und Löschung von Daten beinhalten, die für die jeweiligen
          Verarbeitungen vorrangig gelten.
        </p>
        <h2 id="m15">Änderung und Aktualisierung der Datenschutzerklärung</h2>
        <p>
          Wir bitten Sie, sich regelmäßig über den Inhalt unserer
          Datenschutzerklärung zu informieren. Wir passen die
          Datenschutzerklärung an, sobald die Änderungen der von uns
          durchgeführten Datenverarbeitungen dies erforderlich machen. Wir
          informieren Sie, sobald durch die Änderungen eine Mitwirkungshandlung
          Ihrerseits (z.B. Einwilligung) oder eine sonstige individuelle
          Benachrichtigung erforderlich wird.
        </p>
        <p>
          Sofern wir in dieser Datenschutzerklärung Adressen und
          Kontaktinformationen von Unternehmen und Organisationen angeben,
          bitten wir zu beachten, dass die Adressen sich über die Zeit ändern
          können und bitten die Angaben vor Kontaktaufnahme zu prüfen.
        </p>
        <h2 id="m10">Rechte der betroffenen Personen</h2>
        <p>
          Ihnen stehen als Betroffene nach der DSGVO verschiedene Rechte zu, die
          sich insbesondere aus Art. 15 bis 21 DSGVO ergeben:
        </p>
        <ul>
          <li>
            <strong>
              Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich aus
              Ihrer besonderen Situation ergeben, jederzeit gegen die
              Verarbeitung der Sie betreffenden personenbezogenen Daten, die
              aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt,
              Widerspruch einzulegen; dies gilt auch für ein auf diese
              Bestimmungen gestütztes Profiling. Werden die Sie betreffenden
              personenbezogenen Daten verarbeitet, um Direktwerbung zu
              betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die
              Verarbeitung der Sie betreffenden personenbezogenen Daten zum
              Zwecke derartiger Werbung einzulegen; dies gilt auch für das
              Profiling, soweit es mit solcher Direktwerbung in Verbindung
              steht.
            </strong>
          </li>
          <li>
            <strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben das
            Recht, erteilte Einwilligungen jederzeit zu widerrufen.
          </li>
          <li>
            <strong>Auskunftsrecht:</strong> Sie haben das Recht, eine
            Bestätigung darüber zu verlangen, ob betreffende Daten verarbeitet
            werden und auf Auskunft über diese Daten sowie auf weitere
            Informationen und Kopie der Daten entsprechend den gesetzlichen
            Vorgaben.
          </li>
          <li>
            <strong>Recht auf Berichtigung:</strong> Sie haben entsprechend den
            gesetzlichen Vorgaben das Recht, die Vervollständigung der Sie
            betreffenden Daten oder die Berichtigung der Sie betreffenden
            unrichtigen Daten zu verlangen.
          </li>
          <li>
            <strong>
              Recht auf Löschung und Einschränkung der Verarbeitung:
            </strong>{" "}
            Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht, zu
            verlangen, dass Sie betreffende Daten unverzüglich gelöscht werden,
            bzw. alternativ nach Maßgabe der gesetzlichen Vorgaben eine
            Einschränkung der Verarbeitung der Daten zu verlangen.
          </li>
          <li>
            <strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das
            Recht, Sie betreffende Daten, die Sie uns bereitgestellt haben, nach
            Maßgabe der gesetzlichen Vorgaben in einem strukturierten, gängigen
            und maschinenlesbaren Format zu erhalten oder deren Übermittlung an
            einen anderen Verantwortlichen zu fordern.
          </li>
          <li>
            <strong>Beschwerde bei Aufsichtsbehörde:</strong> Sie haben
            unbeschadet eines anderweitigen verwaltungsrechtlichen oder
            gerichtlichen Rechtsbehelfs das Recht auf Beschwerde bei einer
            Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres
            gewöhnlichen Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des
            mutmaßlichen Verstoßes, wenn Sie der Ansicht sind, dass die
            Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die
            Vorgaben der DSGVO verstößt.
          </li>
        </ul>
        <h2 id="m42">Begriffsdefinitionen</h2>
        <p>
          In diesem Abschnitt erhalten Sie eine Übersicht über die in dieser
          Datenschutzerklärung verwendeten Begrifflichkeiten. Viele der Begriffe
          sind dem Gesetz entnommen und vor allem im Art. 4 DSGVO definiert. Die
          gesetzlichen Definitionen sind verbindlich. Die nachfolgenden
          Erläuterungen sollen dagegen vor allem dem Verständnis dienen. Die
          Begriffe sind alphabetisch sortiert.
        </p>
        <ul class="glossary">
          <li>
            <strong>Content Delivery Network (CDN):</strong> Ein "Content
            Delivery Network" (CDN) ist ein Dienst, mit dessen Hilfe Inhalte
            eines Onlineangebotes, insbesondere große Mediendateien, wie
            Grafiken oder Programm-Skripte mit Hilfe regional verteilter und
            über das Internet verbundener Server, schneller und sicherer
            ausgeliefert werden können.{" "}
          </li>
          <li>
            <strong>IP-Masking:</strong> Als "IP-Masking” wird eine Methode
            bezeichnet, bei der das letzte Oktett, d.h., die letzten beiden
            Zahlen einer IP-Adresse, gelöscht wird, damit die IP-Adresse nicht
            mehr der eindeutigen Identifizierung einer Person dienen kann. Daher
            ist das IP-Masking ein Mittel zur Pseudonymisierung von
            Verarbeitungsverfahren, insbesondere im Onlinemarketing{" "}
          </li>
          <li>
            <strong>Konversionsmessung:</strong> Die Konversionsmessung (auch
            als "Besuchsaktionsauswertung" bezeichnet) ist ein Verfahren, mit
            dem die Wirksamkeit von Marketingmaßnahmen festgestellt werden kann.
            Dazu wird im Regelfall ein Cookie auf den Geräten der Nutzer
            innerhalb der Webseiten, auf denen die Marketingmaßnahmen erfolgen,
            gespeichert und dann erneut auf der Zielwebseite abgerufen.
            Beispielsweise können wir so nachvollziehen, ob die von uns auf
            anderen Webseiten geschalteten Anzeigen erfolgreich waren.{" "}
          </li>
          <li>
            <strong>Personenbezogene Daten:</strong> "Personenbezogene Daten“
            sind alle Informationen, die sich auf eine identifizierte oder
            identifizierbare natürliche Person (im Folgenden "betroffene
            Person“) beziehen; als identifizierbar wird eine natürliche Person
            angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung
            zu einer Kennung wie einem Namen, zu einer Kennnummer, zu
            Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu einem
            oder mehreren besonderen Merkmalen identifiziert werden kann, die
            Ausdruck der physischen, physiologischen, genetischen, psychischen,
            wirtschaftlichen, kulturellen oder sozialen Identität dieser
            natürlichen Person sind.{" "}
          </li>
          <li>
            <strong>Profile mit nutzerbezogenen Informationen:</strong> Die
            Verarbeitung von "Profilen mit nutzerbezogenen Informationen", bzw.
            kurz "Profilen" umfasst jede Art der automatisierten Verarbeitung
            personenbezogener Daten, die darin besteht, dass diese
            personenbezogenen Daten verwendet werden, um bestimmte persönliche
            Aspekte, die sich auf eine natürliche Person beziehen (je nach Art
            der Profilbildung können dazu unterschiedliche Informationen
            betreffend die Demographie, Verhalten und Interessen, wie z.B. die
            Interaktion mit Webseiten und deren Inhalten, etc.) zu analysieren,
            zu bewerten oder, um sie vorherzusagen (z.B. die Interessen an
            bestimmten Inhalten oder Produkten, das Klickverhalten auf einer
            Webseite oder den Aufenthaltsort). Zu Zwecken des Profilings werden
            häufig Cookies und Web-Beacons eingesetzt.{" "}
          </li>
          <li>
            <strong>Remarketing:</strong> Vom "Remarketing“ bzw. "Retargeting“
            spricht man, wenn z.B. zu Werbezwecken vermerkt wird, für welche
            Produkte sich ein Nutzer auf einer Webseite interessiert hat, um den
            Nutzer auf anderen Webseiten an diese Produkte, z.B. in
            Werbeanzeigen, zu erinnern.{" "}
          </li>
          <li>
            <strong>Verantwortlicher:</strong> Als "Verantwortlicher“ wird die
            natürliche oder juristische Person, Behörde, Einrichtung oder andere
            Stelle, die allein oder gemeinsam mit anderen über die Zwecke und
            Mittel der Verarbeitung von personenbezogenen Daten entscheidet,
            bezeichnet.{" "}
          </li>
          <li>
            <strong>Verarbeitung:</strong> "Verarbeitung" ist jeder mit oder
            ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede
            solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten.
            Der Begriff reicht weit und umfasst praktisch jeden Umgang mit
            Daten, sei es das Erheben, das Auswerten, das Speichern, das
            Übermitteln oder das Löschen.{" "}
          </li>
          <li>
            <strong>Zielgruppenbildung:</strong> Von Zielgruppenbildung (bzw.
            "Custom Audiences“) spricht man, wenn Zielgruppen für Werbezwecke,
            z.B. Einblendung von Werbeanzeigen, bestimmt werden. So kann z.B.
            anhand des Interesses eines Nutzers an bestimmten Produkten oder
            Themen im Internet geschlussfolgert werden, dass dieser Nutzer sich
            für Werbeanzeigen für ähnliche Produkte oder den Onlineshop, in dem
            er die Produkte betrachtet hat, interessiert. Von "Lookalike
            Audiences“ (bzw. ähnlichen Zielgruppen) spricht man wiederum, wenn
            die als geeignet eingeschätzten Inhalte Nutzern angezeigt werden,
            deren Profile bzw. Interessen mutmaßlich den Nutzern, zu denen die
            Profile gebildet wurden, entsprechen. Zu Zwecken der Bildung von
            Custom Audiences und Lookalike Audiences werden im Regelfall Cookies
            und Web-Beacons eingesetzt.{" "}
          </li>
        </ul>
        <p class="seal">
          <a
            href="https://datenschutz-generator.de/?l=de"
            title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            Erstellt mit kostenlosem Datenschutz-Generator.de von Dr. Thomas
            Schwenke
          </a>
        </p>
      </div>
    );
  }
}

export default PrivacyNotice;
