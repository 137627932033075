export const TRANSLATIONS = {
  en_DE: {
    language_toggle: {
      flag_country: "UK",
      label: "EN",
      url: "/de_DE",
    },
    support_us: {
      label: "support us",
      url: "https://bit.ly/2QfVKAs",
    },
    mission_section: {
      title: <>mutcho’s mission</>,
      description:
        "We want to establish a matching app with an anonymous question-answer game that leads to interpersonal connections by focusing on common interests, values, and vibes.",
    },
    video_embed_url: "https://www.youtube.com/embed/3CscHi1QDiY",

    app_introduction_section: [
      {
        title: "Onboarding",
        description:
          "We want to establish a matching app with an anonymous question-answer game that leads to interpersonal connections by focusing on common interests, values, and vibes.",
        display: true,
      },

      {
        title: "Listing creation",
        description:
          "Just click on the plus, select the category and gender, as well as filters that are important to you. You can already create your first round - here you can choose from three different question types.",
        display: true,
      },
      {
        title: "Participation",
        description:
          "Are you interested in a listing? Click on it, learn more about the person and quiz your way to a match.",
        display: true,
      },
      {
        title: "Administration",
        description:
          "Here is how to manage your listing. Add more rounds, edit existing rounds and view statistics.",
        display: true,
      },
      {
        title: "Feed view",
        description: "tbd",
        display: true,
      },
    ],
    vision_section: {
      title: <>mutcho’s vision</>,
      description: (
        <>
          We believe that interpersonal connections matter in life and that this
          aspect is sometimes lacking in the fast-paced world.
          <br />
          We want to move away from the "Swiping Society" towards the
          "Connecting Community" and help people to connect with each other.
        </>
      ),
    },
    qna_section: [
      {
        title: "When will mutcho be available?",
        description:
          "With your support, we plan to go live in the middle of this year.",
      },
      {
        title: "Do I have to pay for mutcho or is it free? ",
        description: (
          <>
            mutcho is free of charge. Optionally, with our premium version
            "mutcho gusto", we offer you additional features for which you would
            have to pay.
          </>
        ),
      },
      {
        title: "Where can I download mutcho?",
        description:
          "As soon as mutcho is available for download, you can get it from the App Store and Play Store. Join our mailing list to stay up to date.",
      },
      {
        title: "What does the premium plan (mutcho gusto) offer? ",
        description:
          "mutcho gusto makes it possible for you to change your location, to create listings more often (daily instead of every three days), to choose among six (instead of four) finalists, to select an additional winner, and to request a match once a day even though you did not successfully contest the listing. All this, of course, without advertising.",
      },
      {
        title: "Who is behind mutcho? ",
        description: (
          <>
            We are two siblings – Fabienne and Sebastian – as well as Lisa and
            Mister Tech, who prefers to stay out of the public eye.
            <br />
            <br />
            Fabienne (26) is in daily contact with startups from all over
            Germany in her role as 'Operations Manager' in the digital
            department of Germany's largest food retailer.
            <br />
            <br />
            Sebastian (30) deals with software development issues on a daily
            basis as a 'Product Owner', also in the digital department of what
            is again Germany's largest sporting goods manufacturer. Previously,
            he worked as a business consultant in the field of retail
            digitalization.
            <br />
            <br />
            Mister Tech started as a developer more than 13 years ago and is now
            employed as a 'Solution Architect' at a global IT company, where he
            evaluates and decides how new software is implemented in order to
            meet all requirements on the one hand and to be best prepared for
            the future on the other.
          </>
        ),
      },
      {
        title: "How can I register?",
        description:
          "You can register with your email or via a social login (Facebook, Google or Apple). Additionally, a verification with your mobile number is required.",
      },
      {
        title: "Who can use mutcho?",
        description: "mutcho is for everyone who is at least 18 years old.",
      },
    ],
    stay_tuned_section: {
      title: (
        <>
          Stay tuned! <br />
          We're launching soon.
        </>
      ),
      email: "Email",
      subscribe: "subscribe",
    },
    lets_chat_section: {
      title: "Let's chat!",
      name: "Name",
      email: "Email",
      message_placeholder_text: "Here goes your message...",
      send_message: "send message",
    },

    social_section: {
      title: "Follow us on social media and stay up to date.",
      links: [
        {
          type: "facebook",
          url: "https://www.facebook.com/mutchoapp",
          display: true,
        },
        {
          type: "instagram",
          url: "https://www.instagram.com/mutcho.app/",
          display: true,
        },
        {
          type: "youtube",
          url: "https://www.youtube.com/watch?v=3CscHi1QDiY",
          display: true,
        },
      ],
    },

    footer_section: {
      copyright_text: "mutcho 2021 – all rights reserved",
      links: [
        {
          label: "imprint & privacy notice",
          url: "/en_DE/imprint-and-privacy-notice",
        },
      ],
    },
  },

  de_DE: {
    language_toggle: {
      flag_country: "DE",
      label: "DE",
      url: "/en_DE",
    },
    support_us: {
      label: "unterstütze uns auf",
      url: "https://bit.ly/2QfVKAs",
    },
    mission_section: {
      title: <>mutchos Mission</>,
      description:
        "Wir möchten eine Matching-App mit einem anonymen Frage-Antwortspiel etablieren, die durch den Fokus auf gemeinsame Interessen, Werte und Vibes zu zwischenmenschlichen Verbindungen führt.",
    },
    video_embed_url: "https://www.youtube.com/embed/3CscHi1QDiY",

    app_introduction_section: [
      {
        title: "Onboarding",
        description:
          "We want to establish a matching app with an anonymous question-answer game that leads to interpersonal connections by focusing on common interests, values, and vibes.",
        display: true,
      },

      {
        title: "Listing creation",
        description:
          "Just click on the plus, select the category and gender, as well as filters that are important to you. You can already create your first round - here you can choose from three different question types.",
        display: true,
      },
      {
        title: "Participation",
        description:
          "Are you interested in a listing? Click on it, learn more about the person and quiz your way to a match.",
        display: true,
      },
      {
        title: "Administration",
        description:
          "Here is how to manage your listing. Add more rounds, edit existing rounds and view statistics.",
        display: true,
      },
      {
        title: "Feed view",
        description: "tbd",
        display: true,
      },
    ],
    vision_section: {
      title: <>mutchos Vision</>,
      description: (
        <>
          Wir sind davon überzeugt, dass es im Leben auf zwischenmenschliche
          Verbindungen ankommt und dass dieser Aspekt in der schnelllebigen Welt
          manchmal zu kurz kommt.
          <br />
          Wir wollen weg von der "Swiping Society" hin zur "Connecting
          Community" und den Menschen helfen, sich miteinander zu verbinden.
        </>
      ),
    },
    qna_section: [
      {
        title: "Wann wird mutcho verfügbar sein?",
        description:
          "Mit deiner Unterstützung planen wir Mitte diesen Jahres live zu gehen.",
      },
      {
        title: "Wofür kann ich mutcho nutzen? ",
        description:
          "Du kannst mutcho zum Dating nutzen oder um Leute für gemeinsame Aktivitäten zu finden – sei es zum Badmintonspielen oder sogar für den nächsten Backpacking-Trip.",
      },
      {
        title: "Muss ich für mutcho zahlen oder ist es kostenlos? ",
        description:
          "mutcho ist kostenlos. Optional bieten wir dir mit unserer Premiumvariante “mutcho gusto” weitere Features an, für die du bezahlen müsstest.",
      },
      {
        title: "Wo kann ich mutcho herunterladen? ",
        description:
          "Sobald mutcho zum Download verfügbar ist, kannst du es im App Store und Play Store herunterladen. Trag dich in unsere Mailingliste ein, um auf dem Laufenden zu bleiben.",
      },
      {
        title: "Was bietet die Premium-Variante (mutcho gusto)? ",
        description:
          "mutcho gusto ermöglicht es dir, deinen Standort zu ändern, Listings häufiger zu erstellen (täglich statt alle drei Tage), zwischen sechs (statt vier) Finalisten zu wählen, einen zusätzlichen Gewinner auszuwählen und einmal am Tag ein Match anzufordern, obwohl du das Listing nicht erfolgreich bestritten hast. All das natürlich ohne Werbung.",
      },
      {
        title: "Gibt es ein Affiliate-Programm? ",
        description:
          "Ja, wir bieten ein Affiliate-Programm an. Schreib uns hierfür bitte über das nachfolgende Formular eine Nachricht.",
      },
      {
        title: "Wer steht hinter mutcho? ",
        description: (
          <>
            "Wir sind zwei Geschwister – Fabienne und Sebastian – sowie Lisa und
            Mister Tech, der es bevorzugt sich aus der Öffentlichkeit
            herauszuhalten.
            <br />
            <br />
            Fabienne (26) steht in ihrer Rolle als ‘Operations Managerin’ in der
            digitalen Abteilung des größten deutschen Lebensmitteleinzelhändlers
            täglich mit Startups aus ganz Deutschland in Verbindung.
            <br />
            <br />
            Sebastian (30) hat als ‘Product Owner’, ebenfalls in der digitalen
            Abteilung des wiederum größten deutschen Sportartikelherstellers,
            tagtäglich mit Themen der Softwareentwicklung zu tun. Zuvor war er
            als Geschäftsberater im Bereich der Digitalisierung des
            Einzelhandels tätig.
            <br />
            <br />
            Mister Tech hat vor über 13 Jahren als Entwickler angefangen und ist
            mittlerweile als ‘Solution Architect’ bei einem weltweit tätigen
            IT-Unternehmen angestellt, wo er evaluiert und entscheidet wie neue
            Software implementiert wird, um zum einen allen Anforderungen
            gerecht zu werden und zum anderen bestmöglich für die Zukunft
            gewappnet zu sein. "
          </>
        ),
      },
      {
        title: "Wie kann ich mich registrieren? ",
        description:
          "Du kannst dich mit deiner E-Mail oder über einen Social-Login (Facebook, Google oder Apple) registrieren. Zusätzlich ist eine Verifizierung mit deiner Handynummer erforderlich.",
      },
      {
        title: "Wer kann mutcho nutzen?",
        description: "mutcho ist für alle, die mindestens 18 Jahre alt sind.",
      },
    ],
    stay_tuned_section: {
      title: (
        <>
          Stay tuned! <br />
          Wir launchen bald.
        </>
      ),
      email: "E-Mail",
      subscribe: "anmelden",
    },
    lets_chat_section: {
      title: "Schreib uns!",
      name: "Name",
      email: "E-Mail",
      message_placeholder_text: "Tippe hier ...",
      send_message: "abschicken",
    },

    social_section: {
      title: "Folge uns auf Social Media und bleib auf dem Laufenden.",
      links: [
        {
          type: "facebook",
          url: "https://www.facebook.com/mutchoapp",
          display: true,
        },
        {
          type: "instagram",
          url: "https://www.instagram.com/mutcho.app/",
          display: true,
        },
        {
          type: "youtube",
          url: "https://www.youtube.com/watch?v=3CscHi1QDiY",
          display: true,
        },
      ],
    },

    footer_section: {
      copyright_text: "mutcho 2021 - alle Rechte vorbehalten",
      links: [
        {
          label: "impressum & datenschutz",
          url: "/de_DE/imprint-and-privacy-notice",
        },
      ],
    },
  },
};
