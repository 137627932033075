import React, { Component } from "react";

class CollapsableDescriptionBox extends Component {
  state = {
    isCollapsed: true,
  };

  toggleCollapse = () => {
    this.setState({ isCollapsed: !this.state.isCollapsed });
  };

  render() {
    let { title, description, key } = this.props;

    return (
      <div
        key={key}
        className={`accordion_box ${
          this.state.isCollapsed ? "collapsed" : "expanded"
        } `}
        onClick={(e) => this.toggleCollapse()}
      >
        {/* <div className="accordion" onClick={(e) => this.toggleCollapse()}>
            <div className="title">{title}</div>
            <div className="body">
              <p>{description}</p>
            </div>

            <div>
              <img className="icon" src="images/arrow-down.svg" />
            </div>
          </div> */}

        <div className="accordion">
          <div className="left">
            <div className="title">{title}</div>
            <div className="body">
              <p>{description}</p>
            </div>
          </div>

          <div className="right">
            <div>
              <img
                className="icon"
                src={`images/${
                  this.state.isCollapsed ? "arrow-down.svg" : "arrow-up.svg"
                }`}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CollapsableDescriptionBox;
