import React, { Component } from "react";
import "./styles/signup.css";
import "./styles/flags.css";
import imageBulb from "./images/bulb.png";

class Signup extends Component {
  state = {
    active: false,
    dropdownOpen: false,
  };

  onComponentDidMount() {
    this.setState({ active: true });
  }

  closeOverlay = () => {
    this.setState({ active: false });
  };

  onDropdownClick = (e) => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  onContinueToSite = (e) => {
    let { onCloseSignupOverlay } = this.props;
    onCloseSignupOverlay();
  };

  render() {
    let { dropdownOpen } = this.state;

    return (
      <>
        <div className="container">
          <div className="signup">
            <div>
              <h3>We are launching in Germany first.</h3>
              <p>
                Subscribe to our newsletter and stay up to date on the latest
                news around mutcho. With your sign-up you vote for a country to
                launch after Germany.
              </p>
              <div className="signup_input_controls">
                <input
                  className="input_email"
                  type="text"
                  name="contact_request_email"
                  placeholder="Email"
                />

                <div
                  className="country_input"
                  onClick={(e) => this.onDropdownClick(e)}
                >
                  <div className="current_selected">
                    <div className="flag flag-24 flag-de"></div>
                    <div className="country_name">Germany</div>
                    <div className="drop_down_icon"></div>
                  </div>
                  <div
                    className={`country_options ${
                      dropdownOpen ? "" : "hidden"
                    }`}
                  >
                    <div className="option">
                      <div className="flag flag-24 flag-de"></div>

                      <div className="country_name">Germany</div>
                    </div>
                    <div className="option">
                      <div className="flag flag-24 flag-gb"></div>

                      <div className="country_name">United Kingdom</div>
                    </div>
                    <div className="option">
                      <div className="flag flag-24 flag-fr"></div>

                      <div className="country_name">France</div>
                    </div>
                    <div className="option">
                      <div className="flag flag-24 flag-at"></div>

                      <div className="country_name">Austria</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="btn_container">
              <div
                className="item new_btn_primary_transparent"
                onClick={(e) => this.onContinueToSite()}
              >
                <div className="label">continue to site</div>
              </div>
              <div className="item new_btn_primary ">
                <div className="label">send request</div>
              </div>
              <select name="optiontest" id="optiontest">
                <option value="volvo">Germany</option>
                <option value="saab">United Kingdom</option>
                <option value="mercedes">Frane</option>
                <option value="audi">Austria</option>
                <option value="volvo">Germany</option>
                <option value="saab">United Kingdom</option>
                <option value="mercedes">Frane</option>
                <option value="audi">Austria</option>
                <option value="volvo">Germany</option>
                <option value="saab">United Kingdom</option>
                <option value="mercedes">Frane</option>
                <option value="audi">Austria</option>
                <option value="volvo">Germany</option>
                <option value="saab">United Kingdom</option>
                <option value="mercedes">Frane</option>
                <option value="audi">Austria</option>
              </select>
            </div>
            <div className="graphics">
              <img src={imageBulb} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Signup;
