import React, { Component } from "react";
import HomePage from "./HomePage";
import PrivacyNotice from "./PrivacyNotice";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";

class App extends Component {
  render() {
    return (
      <>
        <div className="App">
          <Router>
            <Switch>
              <Route path="/de_DE/imprint-and-privacy-notice">
                <PrivacyNotice></PrivacyNotice>
              </Route>
              <Route path="/en_DE/imprint-and-privacy-notice">
                <PrivacyNotice></PrivacyNotice>
              </Route>
              <Route exact path="/">
                <Redirect to="/en_DE" />
              </Route>
              <Route path="/en_DE">
                <HomePage locale="en_DE"></HomePage>
              </Route>
              <Route path="/de_DE">
                <HomePage locale="de_DE"></HomePage>
              </Route>

              <Route path="/*">
                <Redirect to="/en_DE" />
              </Route>
            </Switch>
          </Router>
        </div>
      </>
    );
  }
}

export default App;
