import React, { Component } from "react";
import CollapsableDescriptionBox from "./components/CollapsableDescriptionBox";
import { TRANSLATIONS } from "./utils/translations";
import Carousel from "react-elastic-carousel";
import Signup from "./components/Signup/Signup";

let defaultLocale = "de_DE";

class HomePage extends Component {
  state = {
    isSignupOverlayOpen: true,
  };

  onClickSubscribe = () => {
    return null;
  };

  onSendMessage = () => {
    return null;
  };

  onCloseSignupOverlay = () => {
    this.setState({ isSignupOverlayOpen: false });
  };

  render() {
    let { locale } = this.props;
    let _translations = TRANSLATIONS[locale] || TRANSLATIONS[defaultLocale];

    return (
      <>
        <div className="homepage">
          <div>
            {/* <Signup></Signup> */}
            <div className="sticky_debug_container"></div>
            {/* <a href={_translations.language_toggle.url}>
              <div className="language_selector">
                <img
                  width={"50px"}
                  src={`images/flag_${_translations.language_toggle.flag_country}.png`}
                />
                <div className="label">
                  <span style={{ color: "#868686" }}>
                    {_translations.language_toggle.label}
                  </span>
                </div>
              </div>
            </a> */}

            {/* <div id="signup-overlay" className="container overlay_container">
              <Signup></Signup>
            </div> */}

            {/* <div id="signup-overlay" className="overlay_container"></div> */}

            {this.state.isSignupOverlayOpen && (
              <>
                <div className="overlay_container"></div>
                <div
                  id="signup-overlay"
                  className="overlay_content"
                  style={{ margin: "0px", padding: "0px" }}
                >
                  <Signup
                    onCloseSignupOverlay={this.onCloseSignupOverlay}
                  ></Signup>
                </div>
              </>
            )}

            <div id="language_toggle_container">
              <a href={_translations.language_toggle.url}>
                <div className="language_selector">
                  <img
                    width="50px"
                    src={`images/flag_${_translations.language_toggle.flag_country}.png`}
                  />
                  <div className="label">
                    <span> {_translations.language_toggle.label}</span>
                  </div>
                </div>
              </a>
            </div>
            <div className="header_sideeffect_adjustment"></div>
            <div
              id="section_1"
              className="hero fullwidth"
              style={{ position: "relative" }}
            >
              <div>
                <div className="ellipse_hero_left"></div>
              </div>
              <div className="hero_left">
                <img className="" src="images/3hand_left.svg" />
              </div>

              <div style={{ clear: "both" }}></div>

              <div className="hero_center">
                <div className="ellipse_hero_centre"></div>
                <img className="" src="images/new_mutcho_logo.svg" />
              </div>

              <div style={{ clear: "both" }}></div>
              <div className="hero_right">
                <div className="ellipse_hero_right"></div>
                <img src="images/3d-hands-classy-13833.svg" />
              </div>

              <div style={{ clear: "both" }}></div>

              <a
                href={_translations.support_us.url}
                target="_blank"
                rel="noreferrer"
              >
                <div className="container desktop_specific_layout">
                  <div className="btn_primary btn_with_image disable-select">
                    <div className="label flex_container">
                      <div>{_translations.support_us.label}</div>
                      <div>
                        <img src="images/startnext_logo.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div
              className="hero-section-clear"
              style={{ position: "relative" }}
            >
              <div className="ellipse_desktop_hero_bottom_right"></div>
            </div>

            <div
              className="desktop_specific_layout"
              style={{ "margin-top": "-252px" }}
            ></div>

            <div id="section-2" className="container">
              <div className="container_primary">
                <div className="narrow">
                  <div className="saly_top_square no_border_no_shadow">
                    <img src="images/cube-3d.svg" />
                  </div>
                  <div className="saly_top_triangle no_border_no_shadow">
                    <img src="images/SketchExportTriangle.svg" />
                  </div>
                  <div className="saly_bottom_circle no_border_no_shadow">
                    <img src="images/purple-ring-3d.svg" />
                  </div>
                  <div className="saly_bottom_triangle no_border_no_shadow">
                    <img src="images/pyramid-3d.svg" />
                  </div>
                  <img className="white_background" src="images/feed.png" />
                </div>
                <div className="wide">
                  <h2>{_translations.mission_section.title}</h2>
                  <p>{_translations.mission_section.description}</p>
                </div>
              </div>
            </div>

            <div id="section-2a" className="container mobile_specific_layout">
              <div className="container_primary">
                <a
                  href={_translations.support_us.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="btn_primary btn_with_image disable-select">
                    <div className="label flex_container">
                      <div>{_translations.support_us.label}</div>
                      <div>
                        <img src="images/startnext_logo.svg" />
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>

            <div
              id="section-3"
              className="container video-container youtube-video"
            >
              <iframe
                title="Intro Video"
                src={_translations.video_embed_url}
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>

            <div id="section-3b" className="container">
              <div className="desktop_specific_layout">
                <div className="ellipse_wrapper">
                  <div className="ellipse_light_purple_m"></div>
                </div>
              </div>
            </div>

            {/* <div
              id="section-4"
              className="container"
              style={{ position: "relative" }}
            >
              <div className="container_primary grey_gradient">
                <div className="wide">
                  <h2>Onboarding</h2>
                  <p>
                    Amet minim mollit non deserunt ullamco est sit aliqua dolor
                    do amet sint. Velit officia consequat duis enim velit
                    mollit. Exercitation veniam consequat sunt nostrud amet.
                  </p>
                  <div className="progress_bar_container">
                    <div className="dots_container">
                      <div className="dot_complete">
                        <div></div>
                      </div>
                      <div className="dot_complete">
                        <div></div>
                      </div>

                      <div className="dot_incomplete">
                        <div></div>
                      </div>

                      <div className="dot_incomplete">
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="narrow">
                  <div className="background_circle" style={{ opacity: "0.8" }}>
                    <img
                      className="white_background"
                      src="images/nickname.png"
                    />
                    <div className="ellipse_green"></div>
                  </div>
                </div>
              </div>

              <div className="desktop_specific_layout">
                <div className="ellipse_green_m"></div>
                <div className="ellipse_green_s"></div>
              </div>
            </div> */}

            {/* some testing here */}

            <div
              id="section-4"
              className="container"
              style={{ position: "relative" }}
            >
              <div className="container_primary grey_gradient">
                <div className="wide">
                  <Carousel itemsToShow={1}>
                    {_translations.app_introduction_section.map((x) => (
                      <div key={x.title}>
                        <h2>{x.title}</h2>
                        <p>{x.description}</p>
                      </div>
                    ))}
                  </Carousel>
                </div>
                <div className="narrow">
                  <div className="background_circle" style={{ opacity: "0.8" }}>
                    <img
                      className="white_background"
                      src="images/nickname.png"
                    />
                    <div className="ellipse_green"></div>
                  </div>
                </div>
              </div>

              <div className="desktop_specific_layout">
                <div className="ellipse_green_m"></div>
                <div className="ellipse_green_s"></div>
              </div>
            </div>

            <div
              className="container mycarousel_container"
              style={{ display: "none" }}
            >
              <div className="mycarousel" style={{ width: "80%" }}>
                <Carousel itemsToShow={1}>
                  <div id="1">
                    <h2>WIP</h2>
                    <p>
                      Amet minim mollit non deserunt ullamco est sit aliqua
                      dolor do amet sint. Velit officia consequat duis enim
                      velit mollit. Exercitation veniam consequat sunt nostrud
                      amet.
                    </p>
                  </div>
                  <div id="2">
                    <h2>CAROUSEL</h2>
                    <p>
                      Amet minim mollit non deserunt ullamco est sit aliqua
                      dolor do amet sint. Velit officia consequat duis enim
                      velit mollit. Exercitation veniam consequat sunt nostrud
                      amet.
                    </p>
                  </div>
                </Carousel>
              </div>
              <div className="app_into_screenshot_background">
                <div className="background_circle" style={{ opacity: "0.8" }}>
                  <img
                    width={100}
                    className="white_background"
                    src="images/nickname.png"
                  />
                  <div className="ellipse_green"></div>
                </div>
              </div>
            </div>
            {/* <div
              id="section-4_X"
              className="container"
              style={{ position: "relative", background: "cyan" }}
            >
              <div className="container_primary grey_gradient">
                <div className="wide">
                  <Carousel itemsToShow={1}>
                    <h2>CAROUSEL</h2>
                    <p>
                      Amet minim mollit non deserunt ullamco est sit aliqua
                      dolor do amet sint. Velit officia consequat duis enim
                      velit mollit. Exercitation veniam consequat sunt nostrud
                      amet.
                    </p>
                    <div className="progress_bar_container">
                      <div className="dots_container">
                        <div className="dot_complete">
                          <div></div>
                        </div>
                        <div className="dot_complete">
                          <div></div>
                        </div>

                        <div className="dot_incomplete">
                          <div></div>
                        </div>

                        <div className="dot_incomplete">
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </Carousel>
                </div>
                <div className="narrow">
                  <div className="background_circle" style={{ opacity: "0.8" }}>
                    <img
                      className="white_background"
                      src="images/nickname.png"
                    />
                    <div className="ellipse_green"></div>
                  </div>
                </div>
              </div>

              <div className="desktop_specific_layout">
                <div className="ellipse_green_m"></div>
                <div className="ellipse_green_s"></div>
              </div>
            </div> */}

            <div
              id="section-4_type1"
              className="container mobile_specific_layout"
            >
              <div className="container_primary">
                <div className="wide">
                  <h2>{_translations.vision_section.title}</h2>
                  <p>{_translations.vision_section.description}</p>
                </div>
                <div className="narrow" style={{ position: "relative" }}>
                  <div className="saly_top_square_right  no_border_no_shadow">
                    <img src="images/cube-3d.svg" />
                  </div>
                  <div className="saly_top_triangle_right">
                    <div className=" no_border_no_shadow">
                      <img
                        style={{ width: "100%" }}
                        src="images/SketchExportTriangle.svg"
                      />
                    </div>
                  </div>

                  <div className="saly_bottom_circle_left no_border_no_shadow">
                    <img src="images/purple-ring-3d.svg" />
                  </div>
                  <div className="saly_bottom_triangle_left left no_border_no_shadow">
                    <img src="images/pyramid-3d.svg" />
                  </div>
                  <div className="about_mutcho_compass no_border_no_shadow">
                    <img src="images/compass.png" />
                  </div>
                  <img className="white_background" src="images/match.png" />
                </div>
              </div>
            </div>

            <div
              id="section-4_type2"
              className="container desktop_specific_layout"
            >
              <div className="container_primary">
                <div className="narrow">
                  <div className="saly_top_square_right  no_border_no_shadow">
                    <img src="images/cube-3d.svg" />
                  </div>
                  <div className="saly_top_triangle_right">
                    <div className=" no_border_no_shadow">
                      <img
                        style={{ width: "100%" }}
                        src="images/SketchExportTriangle.svg"
                      />
                    </div>
                  </div>

                  <div className="saly_bottom_circle_left no_border_no_shadow">
                    <img src="images/purple-ring-3d.svg" />
                  </div>
                  <div className="saly_bottom_triangle_left left no_border_no_shadow">
                    <img src="images/pyramid-3d.svg" />
                  </div>
                  <div className="about_mutcho_compass no_border_no_shadow">
                    <img src="images/compass.png" />
                  </div>

                  <img src="images/match.png" />
                </div>
                <div className="wide">
                  <h2>{_translations.vision_section.title}</h2>
                  <p>{_translations.vision_section.description}</p>
                </div>
              </div>
            </div>

            <div id="section_6" className="container">
              {_translations.qna_section.map((x) => (
                <CollapsableDescriptionBox
                  title={x.title}
                  description={x.description}
                  key={x.title}
                ></CollapsableDescriptionBox>
              ))}
            </div>

            <div id="section-51" className="container"></div>

            <div id="section-7_for_mobile" className="mobile_specific_layout">
              <div className="container_secondary">
                <div className="narrow scale_up">
                  <img src="images/handwithphone.svg" />
                </div>
                <div className="wide">
                  <h2>{_translations.stay_tuned_section.title}</h2>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      className="input_email"
                      name="mobile_subscribe_email"
                      type="text"
                      placeholder={_translations.stay_tuned_section.email}
                    />
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="container_primary">
                  <div
                    className="btn_primary"
                    onClick={this.onClickSubscribe("mobile_subscribe_email")}
                  >
                    <div className="label">
                      {_translations.stay_tuned_section.subscribe}
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "80px" }}></div>
            </div>

            <div
              id="section-7_for_desktop"
              className="desktop_specific_layout"
              style={{
                paddingTop: "150px",
                paddingBottom: "200px",
                background: "#b0cfc2",
                position: "relative",
              }}
            >
              <div className="container_secondary">
                <div className="wide">
                  <h2>{_translations.stay_tuned_section.title}</h2>
                  <div className="input_controls_wrapper">
                    <input
                      className="input_email"
                      type="text"
                      name="desktop_subscribe_email"
                      placeholder={_translations.stay_tuned_section.email}
                    />
                    <div
                      className="btn_primary"
                      onClick={this.onClickSubscribe("desktop_subscribe_email")}
                    >
                      <div className="label">
                        {" "}
                        {_translations.stay_tuned_section.subscribe}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="narrow scale_up">
                  <img src="images/handwithphone.svg" />
                </div>
              </div>
            </div>

            <div
              id="section-8"
              className="container new_contact_form"
              style={{ position: "relative" }}
            >
              <div className="container_primary grey_gradient">
                <div className="wide lets_talk__top_margin_adjustment">
                  <h2>{_translations.lets_chat_section.title}</h2>
                  <input
                    className="input_name"
                    type="text"
                    name="contact_request_name"
                    placeholder={_translations.lets_chat_section.name}
                  />
                  <input
                    className="input_email"
                    type="text"
                    name="contact_request_email"
                    placeholder={_translations.lets_chat_section.email}
                  />

                  <textarea
                    className="multiline_input"
                    type="text"
                    name="contact_request_message"
                    placeholder={
                      _translations.lets_chat_section.message_placeholder_text
                    }
                  ></textarea>

                  <div className="btn_primary" onClick={this.onSendMessage()}>
                    <div className="label">
                      {_translations.lets_chat_section.send_message}
                    </div>
                  </div>
                </div>
                <div className="ellipse_posn_y_third">
                  <div className="partial_ellipse_container">
                    <img src="images/Ellipse24.svg" />
                  </div>
                </div>
                <div
                  className="narrow let_talk_section"
                  style={{ overflow: "hidden" }}
                >
                  <div className="visual">
                    <img className="mail_image" src="images/image_mail.svg" />
                    <div className="ellipse_mail_background"></div>
                  </div>

                  <div className="ellipse_posn_y_first"></div>
                  <div className="ellipse_posn_y_second"></div>
                </div>
              </div>
            </div>

            <div id="section_9" className="container">
              <div className="centered_content">
                <p className="dark_primary">
                  {_translations.social_section.title}
                </p>
              </div>

              <div className="centered_content">
                {_translations &&
                  _translations.social_section.links
                    .filter((y) => y.display === true)
                    .map((x) => (
                      <a
                        key={x.url}
                        href={x.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          className="social_icon"
                          src={`images/icon_${x.type}.svg`}
                        />
                      </a>
                    ))}
                {/* <img className="social_icon" src="images/icon_facebook.svg" />
                <img className="social_icon" src="images/icon_instagram.svg" />
                <img className="social_icon" src="images/icon_youtube.svg" /> */}
              </div>
            </div>

            <div
              className="mobile_specific_layout"
              style={{ marginTop: "148px" }}
            ></div>

            <div id="footer_new" className="container footer">
              <div className="logo_wrapper">
                <img className="logo" src="images/mutcho_logo_footer.svg" />
                <div style={{ marginTop: "0px" }}>
                  <span style={{ fontSize: "12px" }}>
                    <span
                      style={{
                        fontFamily: "Arial",
                        fontSize: "0.7em",
                        paddingRight: "0.2em",
                      }}
                    >
                      &#169;
                    </span>
                    {_translations.footer_section.copyright_text}
                  </span>
                </div>
              </div>
              <div className="links_container">
                {_translations.footer_section.links.map((x) => (
                  <div key={x.url}>
                    <span>
                      <a
                        href={_translations.footer_section.links[0].url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {_translations.footer_section.links[0].label}
                      </a>
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default HomePage;
